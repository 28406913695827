import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GridComponent, SortSettingsModel } from '@syncfusion/ej2-angular-grids';
import { forkJoin, Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { RentalService } from 'src/app/services/Rental.service';
import { VendorService } from 'src/app/services/Vendor.service';
import { customCurrencyPipe } from 'src/app/Shared/customCurrency.pipe';
import Swal from 'sweetalert2';
import { SharedDataService } from 'src/app/Shared/SharedData.service';
declare var $: any;
import { DiscountType } from 'src/app/Shared/enum';

@Component({
  selector: 'app-dailyrental-online',
  templateUrl: './dailyrental-online.component.html',
  styleUrls: ['./dailyrental-online.component.scss', '../rent-online.component.scss']
})
export class DailyrentalOnlineComponent implements OnInit, OnDestroy {
  marketId;
  sunday = false;
  monday = false;
  isdirty = false;
  tuesday = false;
  wednesday = false;
  thursay = false;
  friday = false;
  saturday = false;
  @Input() newVendor;
  rentalForm: FormGroup;
  dailyMarketDays = [];
  exclusivedates = [];
  filteredMarketDays = [];
  ResaleOrBusinessHeader = "";
  private subscriptions: Subscription[] = [];
  public loaderIcon: Promise<boolean>;
  defaultPart;
  parts = [];
  selectedDays: Set<number> = new Set();
  vendorId;
  otherRentalCharges = [];
  otherItemList = [];
  displayOtherChargeByBooth;
  otherChargeMarketDay;
  otherItemSearchText;
  selectedOtherCharges = [];
  AllowRentingOtherItems = false;
  selectedOtherItems = [];
  @ViewChild('modalOtherCharges', { static: false }) modalAddNewItems: ElementRef;
  @ViewChild('gridOtherRental', { static: false }) public gridOtherRental: GridComponent;
  @ViewChild('gridDailyRental', { static: false }) public gridDailyRental: GridComponent;
  @ViewChild('modalResaleNo', { static: false }) modalResaleNo: ElementRef;
  @ViewChild('modalDiscountPlans', { static: false }) modalDiscountPlans: ElementRef;
  @Output() isFormDirty = new EventEmitter<any>(); 
  @Output() isCancelClicked = new EventEmitter<any>(); 
  @Output() bookingFormCompleted = new EventEmitter<any>(); 
  @Output() rentalBookingData = new EventEmitter<any>();
  @Output() resaleNoInputRequired = new EventEmitter<any>();
  @Output() businessLicenseRequired = new EventEmitter<any>();
  selectedBoothData = []
  allAvailableBooths = [];
  isboothSelected = true;
  defaultPartId;
  selectedBoothsForRental = [];
  tempRecordNo = 0;
  selectedMarketDays = [];
  selectedBooths = [];
  displayLayout = false;
  resaleAndBusiPopupDisable = true;
  layoutParts;
  warnExcessPayment = false;
  warnZeroPayment = false;
  ResaleNumberRequired = false;
  DaysSellWithoutResaleNumber;
  BusinessLicenseNumberRequired = false;
  DaysSellWithoutBusiLicNumber;
  displaySalesTax = false;
  displayCityTax = false;
  displayOtherChargeSalesTax = false;
  displayOtherChargeCityTax = false;
  exemptResale = false;
  resaleNo = "";
  resaleNoDisplay = false;
  businessLicenseDisplay = false;
  businessLicenseNumber;
  businessLicenseExpiryDate;
  vendorRentalDays = [];
  marketDayCountForResale = 0;
  resaleOptionSelected = false;
  DisplayPayDueDate = false;
  DisplaySpecificDate = false;
  DisplayOptionValue;
  vendorProducts;
  defaultProduct;
  totalRentalCharge = 0.00;
  totalOtherRentalCharge = 0.00;
  totalSalesTax = 0.00;
  totalSecondLeveltax = 0.00
  totalCharge = 0.00;
  priorBalance = 0.00;
  public textWrapSettings: any = { wrapMode: 'Both' }; // Enable text wrapping
  vendorDiscountPlans =[];
  disableDiscountAmount = true;
  totalDiscountCharge;
  ApplyBookingFee = false;
  ApplyBookingFeeId;
  bookingFeeAmount;
  secondTaxName = "";

  constructor(private renderer: Renderer2,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    public rentalService: RentalService,
    public vendorService: VendorService,
    private customCurrency: customCurrencyPipe,
    private datePipe: DatePipe,
    private sharedDataService: SharedDataService,
    private marketInfoService: MarketInfoService) {
    this.rentalForm = this.formbuilder.group({
      marketWeekSunday: [0],
      marketWeekMonday: [0],
      marketWeekTuesday: [0],
      marketWeekWednesday: [0],
      marketWeekThursday: [0],
      marketWeekFriday: [0],
      marketWeekSaturday: [0],
      marketParts: ['']
    })
  }
  sortOptions: SortSettingsModel = {
    columns: [{ field: 'RentalDay', direction: 'Ascending' }, { field: 'BoothNumber', direction: 'Ascending' }]
  };
  sortOtherItemsOptions: SortSettingsModel = {
    columns: [{ field: 'OtherItem', direction: 'Ascending' }, { field: 'BoothNo', direction: 'Ascending' }]
  };
  minExpiryDate = new Date();
  ngOnInit(): void 
  {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.vendorId = params['vendorId'];
    });
    this.generateTempRecordNumber();   
    this.getMarketDays();
    this.getVendorProducts();
    this.getParts();
    this.getMarketVendorSettings();
    this.getVendorBusinessLicenseSettings();
    this.getVendorBalances();
    this.getOtherRentalItems();
    if (this.sharedDataService.paymentSharedData)
      this.loadSelectedBooths();
  }

  getVendorProducts() {
    if(this.vendorId){
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.GetVendorProductsById(this.marketId, this.vendorId).subscribe((res: any) => {
      if (res) {
        this.vendorProducts = res.body;
        this.defaultProduct = this.vendorProducts[0].ProductId;
        this.loaderIcon = Promise.resolve(false);
      }
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }
  }

  generateTempRecordNumber() {
    this.subscriptions.push(this.rentalService.generateTempRecordNumber(this.marketId).subscribe((response) => {
      if (response.Success) {
        this.tempRecordNo = response.Message;
      }
    }));
  }

  getVendorBalances() {
    this.vendorDiscountPlans =[];
  forkJoin({
    vendorBalanceRes: this.rentalService.getVendorBalances(this.vendorId, this.marketId),
    discountPlansRes:    this.vendorService.GetDiscountPlan(this.vendorId,this.marketId)
  }).subscribe(
    ({ vendorBalanceRes, discountPlansRes }: any) => {
      if (vendorBalanceRes) {
        this.priorBalance = vendorBalanceRes.PriorBalance;
      }
      if(discountPlansRes){
        if (discountPlansRes) {
          for (const el of discountPlansRes) {
            if (el.Daily && el.OnlineTransactions) {
              const ob = {
                PlanSeqId: el.PlanSeqId,
                PlanId: el.PlanId,
                PlanName: el.PlanName,
                EffectiveFrom: this.datePipe.transform(el.EffectiveFrom, 'MM/dd/yyyy'),
                ExpiryDate: this.datePipe.transform(el.ExpiryDate, 'MM/dd/yyyy'),
                DiscountType: el.DiscountType == 0 ? DiscountType.Percentage : DiscountType.FixedAmount,
                DiscountValue: el.DiscountValue,
                OtherItems: el.OtherItems,
                Daily: el.Daily,
                Weekly: el.Weekly,
                Monthly: el.Monthly,
                CombinedWeekly: el.CombinedWeekly,
                CombinedMonthly: el.CombinedMonthly,
                IsExceedAmount: el.IsExceedAmount,
                ExceedAmount: el.ExceedAmount,
                BalanceDue: el.BalanceDue,
                Notes: el.Notes,
                QDR: el.QDR,
                CheckAll: el.Selected,
                AutoSelected: el.Selected,
                DisableDiscount: el.Selected,
                AppliedDiscount: this.customCurrency.transform("0.00"),
                SalesTaxAmount: this.customCurrency.transform("0.00"),
                SecondLevelTaxAmount: this.customCurrency.transform("0.00"),
                SalesTaxApplied: el.SalesTax,
                SecondLevelTaxApplied: el.SecondLevelTax,
              }
              this.vendorDiscountPlans.push(ob);
            }
          }
          this.loaderIcon = Promise.resolve(false);
          this.disableDiscountAmount = this.vendorDiscountPlans.length > 0 ? false : true;
        }
        this.getDiscounts();
      }
    })
  }

  getParts() {
    const sub = this.marketInfoService.getParts(this.marketId).subscribe((res: any) => {
      if (res) {
        this.parts = res;
        this.getWebRentalSetting();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  getWebRentalSetting() {
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.defaultPart = res.DefaultLayoutPart;
        this.rentalForm.get('marketParts').setValue(this.defaultPart);
        this.ApplyBookingFee = res.ApplyBookingFee;
        this.ApplyBookingFeeId = res.ApplyBookingFeeId;
        this.AllowRentingOtherItems = res.AllowRentingOtherItems;
        if (this.ApplyBookingFee) {
          this.subscriptions.push(this.rentalService.getBookingFeeById(this.marketId, this.ApplyBookingFeeId).subscribe((resFee: any) => {
            if (resFee) {
              this.bookingFeeAmount = resFee.OtherItemCharge;
            }
          }));
        }
        this.getRentalPaymentSettings();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  openLayoutPopup() {
    this.refreshAddBooth();
  }

  getMarketDays() {
    const sub = this.marketInfoService.getMarketDays(this.marketId).subscribe((res: any) => {
      if (res) {
        this.sunday = res.Sunday;
        this.monday = res.Monday;
        this.tuesday = res.Tuesday;
        this.wednesday = res.Wednesday;
        this.thursay = res.Thursday;
        this.friday = res.Friday;
        this.saturday = res.Saturday;
        this.selectedDays["Sunday"] = res.Sunday;
        this.selectedDays["Monday"] = res.Monday;
        this.selectedDays["Tuesday"] = res.Tuesday;
        this.selectedDays["Wednesday"] = res.Wednesday;
        this.selectedDays["Thursday"] = res.Thursday;
        this.selectedDays["Friday"] = res.Friday;
        this.selectedDays["Saturday"] = res.Saturday;
        this.rentalForm.get('marketWeekSunday').setValue(this.sunday);
        this.rentalForm.get('marketWeekMonday').setValue(this.monday);
        this.rentalForm.get('marketWeekTuesday').setValue(this.tuesday);
        this.rentalForm.get('marketWeekWednesday').setValue(this.wednesday);
        this.rentalForm.get('marketWeekThursday').setValue(this.thursay);
        this.rentalForm.get('marketWeekFriday').setValue(this.friday);
        this.rentalForm.get('marketWeekSaturday').setValue(this.saturday);
        this.getDailyMarkets();
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  openLayout() {
    if (this.selectedMarketDays.length == 0) {
      Swal.fire({
        text: $localize`Please select a Rental Day`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
    else if (this.rentalForm.value['marketParts'] == 0) {
      Swal.fire({
        text: $localize`Please select a part`,
        icon: "warning",
        confirmButtonText: $localize`Ok`,
      });
    }
    else {
      //  this.hideTopHeaders.emit(true);
      this.layoutParts = this.rentalForm.value['marketParts'];
      this.sharedDataService.dailyRentalSelectedDays = this.selectedMarketDays;
      this.sharedDataService.dailyRentalSelectedBooths = this.selectedBooths.filter(e => e.NewBooth == true);
      this.sharedDataService.tempRecordNo = this.tempRecordNo;
      this.displayLayout = true;
    }
  }

  getDailyMarkets() {
    this.subscriptions.push(this.marketInfoService.getDailyMarketDays(this.marketId).subscribe((response) => {
      if (response) {
        this.dailyMarketDays = response;
        this.filteredMarketDays = this.dailyMarketDays;
        this.otherChargeMarketDay = this.dailyMarketDays[0].MarketDays;
      }
    }, (err) => {
    }));
  }

  toggleDaySelection(day: string, event: any) {
    this.selectedDays[day] = event.target.checked;
    this.filterDates();
  }

  filterDates() {
    this.filteredMarketDays = this.dailyMarketDays.filter(item => {
      const dayName = this.getDayNameFromDate(item.MarketDays);
      return this.selectedDays[dayName];
    });
  }

  getDayNameFromDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { weekday: 'long' });
  }

  checkboxChanged(args, item: any) {
    if (args.srcElement.checked) {
      this.selectedMarketDays.push(new Date(item.MarketDays));
    } else {
      this.selectedMarketDays = this.selectedMarketDays.filter(day => day.getTime() !== new Date(item.MarketDays).getTime());
    }
    this.allAvailableBooths = [];
  }

  refreshAddBooth() {
    this.getDailyMarkets();
    this.selectedBoothData = [];
    this.allAvailableBooths = [];
    this.isboothSelected = true;   
    this.rentalForm.get('marketParts').setValue(!this.defaultPart  ? this.parts[0].PartId : this.defaultPart);
    if (this.selectedBoothsForRental.length > 0) {
      this.selectedBoothsForRental.forEach(ele => {
        this.selectedMarketDays.forEach(date => {
          const temp = {
            TempRecordNo: this.tempRecordNo.toString(),
            BoothNumber: ele.BoothNumber,
            StartDate: new Date(date),
            RentalType: 1
          }
          this.subscriptions.push(
            this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
            })
          );
        });
      });
    }
    this.selectedMarketDays = [];
    this.selectedBoothsForRental = [];
  }

  searchOtherItem() {
    this.otherRentalCharges = this.otherItemList;
    if (this.otherItemSearchText != '') {
      this.otherRentalCharges = this.otherItemList.filter((ele) => ele.OtherItem.toString().toLowerCase().indexOf(this.otherItemSearchText.toLowerCase()) !== -1);
    }
  }

  getOtherRentalItems() {
    this.subscriptions.push(this.rentalService.getOtherRentalItems(this.marketId).subscribe((response) => {
      if (response) {
        response.map((el, i) => {
          if (el.WebRental) {
            const ob = {
              Id: el.OtherItemId,
              OtherItem: el.OtherItem,
              OtherItemCharge: this.customCurrency.transform(el.OtherItemCharge),
              Quantity: 0,
              OtherItemtotal: this.customCurrency.transform('0'),
              othChgBooths: "none"
            }
            this.otherRentalCharges.push(ob);
            this.otherItemList.push(ob);
          }
        });
      }
    }, (err) => {
    }));
  }

  addOtherCharges() {
    if (this.selectedOtherCharges) {
      this.loaderIcon = Promise.resolve(true);
      this.selectedOtherCharges.map((el, i) => {
        var obj = {
          MarketId: this.marketId,
          OtherItemId: el.Id,
          VendorId: this.vendorId,
          Quantity: el.Quantity,
          OtherItemCharge: el.OtherItemCharge.includes('(') && el.OtherItemCharge.includes(')') ? (-1) * parseFloat(el.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(el.OtherItemCharge.replace(/[^0-9.-]/g, '')),
          WRNewVendorRequest: this.newVendor
        }

        const index = this.selectedOtherItems.findIndex(item => item.Id === el.Id && this.datePipe.transform(item.RentalDay, 'MM/dd/yyyy') == this.datePipe.transform(this.otherChargeMarketDay, 'MM/dd/yyyy') && item.NewItem == true && item.BoothNo === el.othChgBooths && item.Reference == el.Reference);
        this.subscriptions.push(this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
          var newItem = [];
          if (index !== -1) {
            var otherItemTotal = this.selectedOtherItems[index].OtherItemtotal;
            var salesTax = this.selectedOtherItems[index].SalesTax;
            var cityTax = this.selectedOtherItems[index].CityTax;
            this.totalOtherRentalCharge -= otherItemTotal.toString().includes('(') && otherItemTotal.toString().includes(')') ? -1 * parseFloat(otherItemTotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(otherItemTotal.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax -= salesTax.toString().includes('(') && salesTax.toString().includes(')') ? -1 * parseFloat(salesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(salesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax -= cityTax.toString().includes('(') && cityTax.toString().includes(')') ? -1 * parseFloat(cityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(cityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            const ob = {
              Id: el.Id,
              OtherItem: el.OtherItem,
              OtherItemCharge: el.OtherItemCharge,
              Quantity: el.Quantity,
              OtherItemtotal: el.OtherItemtotal,
              RentalDay: this.datePipe.transform(el.RentalDay, "MM/dd/YYYY"),
              SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
              CityTax: this.customCurrency.transform(res.CityTax.toString()),
              BoothNo: el.othChgBooths,
              Reference: el.Reference,
              NewItem: true
            }
            this.selectedOtherItems[index] = ob;
            this.gridOtherRental.refresh();
          }
          else {
            const ob = {
              Id: el.Id,
              OtherItem: el.OtherItem,
              OtherItemCharge: el.OtherItemCharge,
              Quantity: el.Quantity,
              OtherItemtotal: el.OtherItemtotal,
              RentalDay: this.datePipe.transform(el.RentalDay, "MM/dd/YYYY"),
              SalesTax: this.customCurrency.transform(res.SalesTax.toString()),
              CityTax: this.customCurrency.transform(res.CityTax.toString()),
              BoothNo: el.othChgBooths,
              Reference: el.Reference,
              NewItem: true
            }
            newItem.push(JSON.parse(JSON.stringify(ob)));
          }
          this.isdirty = true;
          this.isFormDirty.emit(true);
          if (newItem.length > 0) {
            this.selectedOtherItems = this.selectedOtherItems.concat(newItem);
          }
          this.totalOtherRentalCharge += el.OtherItemtotal.toString().includes('(') && el.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(el.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(el.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
          this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
          this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;  
          this.getDiscounts();
        }, err => {
          this.loaderIcon = Promise.resolve(false);
        }));
      });

      this.loaderIcon = Promise.resolve(false);
      const modal = this.modalAddNewItems.nativeElement;
      if (modal.classList.contains('show')) {
        modal.style.display = 'none';
        modal.classList.remove('show');
      }
    }
  }

  getBoothsAndItemsForOtherCharges() {

  }

  isDateSelected(item: any): boolean {
    var isItemSelected = this.selectedOtherItems.some(
      (element) =>
        this.datePipe.transform(element.RentalDay, 'MM/dd/yyyy') ===
        this.datePipe.transform(this.otherChargeMarketDay, 'MM/dd/yyyy') &&
        element.Id === item.Id && element.NewItem == true
    );

    if (!isItemSelected) {
      isItemSelected = this.selectedOtherCharges.some(
        (element) =>
          this.datePipe.transform(element.RentalDay, 'MM/dd/yyyy') ===
          this.datePipe.transform(this.otherChargeMarketDay, 'MM/dd/yyyy') &&
          element.Id === item.Id
      );
    }

    !isItemSelected ? item.otherItemChecked = false : item.otherItemChecked = true;
    item.othChgBooths = "none";
    return isItemSelected;
  }

  setDefaultRentalItem(args, selectedItems) {
    selectedItems.Quantity = 0;
    selectedItems.OtherItemtotal = this.customCurrency.transform("0").toString();
    selectedItems.Reference = "";
    if (args.srcElement.checked) {
      selectedItems.otherItemChecked = true;
      selectedItems.Quantity = 1
      const isNegative = selectedItems.OtherItemCharge.includes('(') && selectedItems.OtherItemCharge.includes(')');
      const totalValue = parseFloat(selectedItems.OtherItemCharge.replace(/[^0-9.-]/g, ''));
      const finalCharge = isNegative ? -totalValue : totalValue;
      selectedItems.OtherItemtotal = this.customCurrency.transform((finalCharge * selectedItems.Quantity).toString());
      this.selectedOtherCharges.push({
        Id: selectedItems.Id,
        OtherItem: selectedItems.OtherItem,
        OtherItemCharge: selectedItems.OtherItemCharge,
        Quantity: selectedItems.Quantity,
        OtherItemtotal: selectedItems.OtherItemtotal,
        othChgBooths: selectedItems.othChgBooths,
        RentalDay: this.datePipe.transform(this.otherChargeMarketDay, "MM/dd/YYYY"),
      });
    } else {
      selectedItems.otherItemChecked = false;
      const index = this.selectedOtherCharges.findIndex(item => item.Id === selectedItems.Id);
      if (index !== -1) {
        this.selectedOtherCharges.splice(index, 1);
      }

      this.subscriptions.push(this.rentalService.getOtherRentalItems(this.marketId).subscribe((response) => {
        if (response) {
          const masterChargeRecord = response.filter(item => item.OtherItemId == selectedItems.Id);
          if (masterChargeRecord) {
            selectedItems.OtherItemCharge = this.customCurrency.transform(masterChargeRecord[0].OtherItemCharge);
          }
        }
      }, (err) => {
      }));
    }
  }

  otherItemUpdate(selectedItems) {
    if (selectedItems.select) {
      if (selectedItems.Quantity > 0) {
        const index = this.selectedOtherCharges.findIndex(item => item.Id === selectedItems.Id && this.datePipe.transform(item.RentalDay, "MM/dd/YYYY") == this.datePipe.transform(this.otherChargeMarketDay, "MM/dd/YYYY"));
        const isNegative = selectedItems.OtherItemCharge.includes('(') && selectedItems.OtherItemCharge.includes(')');
        const totalValue = parseFloat(selectedItems.OtherItemCharge.replace(/[^0-9.-]/g, ''));
        const finalCharge = isNegative ? -totalValue : totalValue;
        selectedItems.OtherItemCharge = this.customCurrency.transform(finalCharge.toString())
        selectedItems.OtherItemtotal = this.customCurrency.transform((finalCharge * selectedItems.Quantity).toString())
        if (index !== -1) {
          var obj = {
            Id: selectedItems.Id,
            OtherItem: selectedItems.OtherItem,
            OtherItemCharge: selectedItems.OtherItemCharge,
            Quantity: selectedItems.Quantity,
            OtherItemtotal: selectedItems.OtherItemtotal,
            othChgBooths: selectedItems.othChgBooths,
            RentalDay: this.datePipe.transform(this.otherChargeMarketDay, "MM/dd/YYYY"),
            Reference: selectedItems.Reference
          };
          this.selectedOtherCharges[index] = obj;
        }
        else {
          var objNew = {
            Id: selectedItems.Id,
            OtherItem: selectedItems.OtherItem,
            OtherItemCharge: selectedItems.OtherItemCharge,
            Quantity: selectedItems.Quantity,
            OtherItemtotal: selectedItems.OtherItemtotal,
            othChgBooths: selectedItems.othChgBooths,
            RentalDay: this.datePipe.transform(this.otherChargeMarketDay, "MM/dd/YYYY"),
            Reference: selectedItems.Reference
          };
          this.selectedOtherCharges.push(objNew);
        }
      }
    }
  }

  selectedBoothsFromLayout(data) {
    window.scrollTo(0, 0);
    this.selectedMarketDays.forEach(date => {
      this.selectedBooths = this.selectedBooths.filter(e => !e.NewBooth);
    });
    this.selectedBooths = this.selectedBooths.concat(this.sharedDataService.dailyRentalSelectedBooths);

    data.forEach(el => {
      this.selectedMarketDays.forEach(date => {
        var recordExist = this.sharedDataService.dailyRentalSelectedBooths.filter(e => e.BoothNumber == el.BoothNumber && this.datePipe.transform(e.RentalDay, 'MM/dd/yyyy') == this.datePipe.transform(date, 'MM/dd/yyyy'));
        if (recordExist.length == 0) {
          var index = this.selectedBoothData.findIndex(e => e.BoothNumber == el.BoothNumber && e.BoothTypeId == el.BoothTypeId)
          if (index == -1) {
            this.selectedBoothData.push(
              {
                BoothNumber: el.BoothNumber,
                BoothType: el.BoothType,
                BoothTypeId: el.BoothTypeId,
                BoothDescription: el.BoothDescription,
              });
          }
        }
      });
    });
    this.displayLayout = false;
    // this.hideTopHeaders.emit(false);
    this.getRentalPaymentSettings();
    if (this.selectedBoothData.length > 0)
      this.validateAndAddSelectedBoothsToGrid();
  }

  getRentalPaymentSettings() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.rentalService.getRentalSettings(this.marketId).subscribe((res: any) => {
      if (res) {
        this.warnExcessPayment = res.WarnExcessPayment;
        this.warnZeroPayment = res.WarnZeroPayment;
        this.displayOtherChargeByBooth = res.DisplayOtherChargesByBooth;
        this.displaySalesTax = res.RentalTaxable;
        this.displayCityTax = res.RentalSecondTaxable;
        this.displayOtherChargeSalesTax = res.OtherRentalTaxable;
        this.displayOtherChargeCityTax = res.OtherRentalSecondTaxable;
        this.gridDailyRental.getColumnByField("SalesTax").visible = this.displaySalesTax;
        this.gridDailyRental.getColumnByField("CityTax").visible = this.displayCityTax;      
        if(this.newVendor){
          this.gridDailyRental.getColumnByField("Product").visible = false;
        }
        this.ResaleNumberRequired = res.ResaleNumberRequired;
        this.DaysSellWithoutResaleNumber = res.DaysSellWithoutResaleNumber;
        this.BusinessLicenseNumberRequired = res.BusinessLicenseNumberRequired;
        this.DaysSellWithoutBusiLicNumber = res.DaysSellWithoutBusiLicNumber;
        this.DisplayPayDueDate = res.DisplayPayDueDate;
        this.DisplaySpecificDate = res.DisplaySpecificDate;
        this.DisplayOptionValue = res.DisplayOptionValue;
        this.secondTaxName = res.SecondTaxName ? res.SecondTaxName: "Sec Level Tax"
        var secLevelTaxRentHeader = this.gridDailyRental.getColumnByField("CityTax"); 
        secLevelTaxRentHeader.headerText = this.secondTaxName;
        if (this.AllowRentingOtherItems) {
          var secLevelTaxOtherRentHeader = this.gridOtherRental.getColumnByField("CityTax");
          this.gridOtherRental.getColumnByField("BoothNo").visible = res.DisplayOtherChargesByBooth;
          this.gridOtherRental.getColumnByField("SalesTax").visible = this.displayOtherChargeSalesTax;
          this.gridOtherRental.getColumnByField("CityTax").visible = this.displayOtherChargeCityTax;
          secLevelTaxOtherRentHeader.headerText = this.secondTaxName;
        }
        const nextDate = this.getNextDate(this.DisplayOptionValue);
        this.gridDailyRental.refresh();
        if (this.AllowRentingOtherItems) {
          this.gridOtherRental.refresh();
        }
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getNextDate(day: number): Date {
    if (this.DisplaySpecificDate) {
      const today = new Date();
      let nextDate = new Date(today.getFullYear(), today.getMonth(), day);
      // If the nextDate is in the past or today, move to the next month
      if (nextDate < today) {
        nextDate.setMonth(nextDate.getMonth() + 1);
      }
      // Handle cases where the day is not valid in the next month (e.g., 31st in February)
      while (nextDate.getDate() !== day) {
        nextDate.setMonth(nextDate.getMonth() + 1);
        nextDate.setDate(day);
      }
      return nextDate;
    }
    else {
      const today = new Date();
      let nextDate = new Date(today.setDate(today.getDate() + day));
      return nextDate;
    }
  }

  validateAndAddSelectedBoothsToGrid() {
    let noResale = true;
    this.vendorRentalDays = [];
    this.marketDayCountForResale = 0
    if ((this.ResaleNumberRequired && !this.exemptResale) || (this.BusinessLicenseNumberRequired)) {

      const currentYear = new Date().getFullYear();
      var selectedBoothRecord = this.selectedBooths.filter(e => e.NewBooth == true && new Date(e.RentalDay).getFullYear() == currentYear);
      if (selectedBoothRecord.length > 0) {
        selectedBoothRecord.forEach(record => {
          const targetDateString = this.datePipe.transform(record.RentalDay, "MM/dd/yyyy");
          const dateExist = this.vendorRentalDays.find(el => {
            const rentalDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
            return rentalDateString === targetDateString;
          });
          if (!dateExist) {
            const formattedDate = this.datePipe.transform(record.RentalDay, "MM/dd/yyyy");
            this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
            this.marketDayCountForResale += 1;
            if (this.marketDayCountForResale > this.DaysSellWithoutResaleNumber) {
              return; // exits the forEach loop when the condition is met
            }
          }
        });
      }

      this.selectedMarketDays.map(date => {
        const targetDateString = this.datePipe.transform(date, "MM/dd/yyyy");
        const dateExist = this.vendorRentalDays.find(el => {
          const rentalDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
          return rentalDateString === targetDateString;
        });
        if (!dateExist) {
          const formattedDate = this.datePipe.transform(date, "MM/dd/yyyy");
          this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
          this.marketDayCountForResale += 1;
          if (this.marketDayCountForResale > this.DaysSellWithoutResaleNumber) {
            return; // exits the forEach loop when the condition is met
          }
        }
      });

      const obj = {
        VendorID: this.vendorId,
        VendorRentalDate: new Date(),
        MarketId: this.marketId
      };

      this.subscriptions.push(this.rentalService.getVendorRentalDays(obj).subscribe((res: any[]) => {
        if (this.marketDayCountForResale <= this.DaysSellWithoutResaleNumber || this.marketDayCountForResale <= this.DaysSellWithoutBusiLicNumber) {
          if (res && res.length > 0) {
            res.forEach(el => {
              const targetDateString = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
              const dateExist = this.vendorRentalDays.find(data => {
                const rentalDateString = this.datePipe.transform(data.VendorRentalDate, "MM/dd/yyyy");
                return rentalDateString === targetDateString;
              });
              if (!dateExist) {
                const formattedDate = this.datePipe.transform(el.VendorRentalDate, "MM/dd/yyyy");
                this.vendorRentalDays.push({ VendorRentalDate: formattedDate });
                this.marketDayCountForResale += 1;
              }
            });
          }
        }

        if (!this.newVendor) {
          if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
            const modal = this.modalResaleNo.nativeElement;
            modal.classList.add('show');
            if (modal.classList.contains('show')) {
              modal.style.display = 'block';
              this.ResaleOrBusinessHeader = "Resale Number"
              this.resaleNoDisplay = true;
              this.businessLicenseDisplay = false;
            }
            noResale = false;
          }
          else if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
            if (!this.businessLicenseNumber || !this.businessLicenseExpiryDate) {
              const modal = this.modalResaleNo.nativeElement;
              modal.classList.add('show');
              if (modal.classList.contains('show')) {
                modal.style.display = 'block';
                this.ResaleOrBusinessHeader = "Business License Number";
                this.resaleNoDisplay = false;
                this.businessLicenseDisplay = true;
              }
              noResale = false;
            }
            else {
              if (this.getDateFromDateTime(new Date(this.businessLicenseExpiryDate)) < this.getDateFromDateTime(new Date())) {
                Swal.fire({
                  title: $localize`Warning`,
                  text: $localize`Business License is expired! Please contact the market`,
                  icon: "warning",
                  confirmButtonText: $localize`Ok`,
                });
              }
              else {
                this.addSelectedBoothsToGrid();
              }
            }
          }
          else {
            noResale = true;
            this.addSelectedBoothsToGrid();
          }
        }
        else {
          if (this.ResaleNumberRequired && !this.exemptResale && !this.resaleNo && this.marketDayCountForResale > this.DaysSellWithoutResaleNumber && !this.resaleOptionSelected) {
            this.resaleNoInputRequired.emit(true)
          }
          if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
            this.businessLicenseRequired.emit(true);
          }
          this.addSelectedBoothsToGrid();
        }
      }));
    } else {
      this.addSelectedBoothsToGrid();
    }
  }

  updateOtherTotalRent(data) {
    if (parseFloat(data.Quantity) == 0) {
      data.Quantity = "1"
    }
    this.totalSalesTax -= data.SalesTax.toString().includes('(') && data.SalesTax.toString().includes(')') ? -1 * parseFloat(data.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.toString().replace(/[^0-9.-]/g, ''));
    this.totalSecondLeveltax -= data.CityTax.toString().includes('(') && data.CityTax.toString().includes(')') ? -1 * parseFloat(data.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.toString().replace(/[^0-9.-]/g, ''));
   this.totalOtherRentalCharge -= data.OtherItemtotal.toString().includes('(') && data.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
    this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
    const index = this.selectedOtherItems.findIndex(item => item.Id === data.Id && this.datePipe.transform(item.RentalDay, 'MM/dd/yyyy') == this.datePipe.transform(this.otherChargeMarketDay, 'MM/dd/yyyy') && item.NewItem == true && item.BoothNo ==data.BoothNo && item.Reference == data.Reference);
    if (index !== -1) {
      var obj = {
        MarketId: this.marketId,
        OtherItemId: data.Id,
        VendorId: this.vendorId,
        Quantity: data.Quantity,
        OtherItemCharge: data.OtherItemCharge.includes('(') && data.OtherItemCharge.includes(')') ? (-1) * parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, '')),
        WRNewVendorRequest: this.newVendor
      }
      this.subscriptions.push(this.rentalService.getOtherItemsRentalCharges(obj).subscribe((res: any) => {
        this.selectedOtherItems[index].SalesTax = this.customCurrency.transform(res.SalesTax.toString());
        this.selectedOtherItems[index].CityTax = this.customCurrency.transform(res.CityTax.toString());
        const isNegative = data.OtherItemCharge.includes('(') && data.OtherItemCharge.includes(')');
        const totalValue = parseFloat(data.OtherItemCharge.replace(/[^0-9.-]/g, ''));
        const finalCharge = isNegative ? -totalValue : totalValue;
        data.OtherItemtotal = this.customCurrency.transform((finalCharge * data.Quantity).toString());
        this.selectedOtherItems[index].OtherItemtotal = data.OtherItemtotal;
        this.selectedOtherItems[index].OtherItemCharge = this.customCurrency.transform(finalCharge.toString());
        this.selectedOtherItems[index].Quantity = data.Quantity;
        this.gridOtherRental.refresh();

        this.totalOtherRentalCharge += data.OtherItemtotal.toString().includes('(') && data.OtherItemtotal.toString().includes(')') ? -1 * parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.toString().replace(/[^0-9.-]/g, ''));
        this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
         this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;   
        this.getDiscounts();
      }));
    }
  }

  onCheckNumKeyPress(event: KeyboardEvent) {
    const allowedChars = /[0-9]/g;
    const key = event.key;
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === '#') {
      return;
    }
    if (!key.match(allowedChars)) {
      event.preventDefault();
    }
  }

  addSelectedBoothsToGrid() {
    this.selectedBoothsForRental = [];
    if (this.selectedBoothData) {
      this.loaderIcon = Promise.resolve(true);
      for (const date of this.selectedMarketDays) {
        this.selectedBoothData.map((el, i) => {
          var obj = {
            RentalDate: new Date(date),
            MarketId: this.marketId,
            BoothTypeId: el.BoothTypeId,
            VendorId: this.vendorId,
            WRNewVendorRequest: this.newVendor
          }
          this.subscriptions.push(this.rentalService.getBoothrentalCharges(obj).subscribe((res: any) => {
            var vendorProducts = !this.newVendor ? this.vendorProducts.filter(x => x.Priority) ? this.vendorProducts.filter(x => x.Priority)[0].ProductId : this.vendorProducts[0].ProductId:"";
            var newbooths = [];
            const ob = {
              DailyRentalId: 0,
              BoothNumber: el.BoothNumber,
              BoothType: el.BoothType,
              BoothTypeId: el.BoothTypeId,
              Product: vendorProducts,
              RentalDay: this.datePipe.transform(date, "MM/dd/YYYY"),
              Rent: this.customCurrency.transform(res.Rent),
              SalesTax: this.customCurrency.transform(res.SalesTax),
              CityTax: this.customCurrency.transform(res.CityTax),
              NewBooth: true
            }
            this.totalRentalCharge += res.Rent.toString().includes('(') && res.Rent.toString().includes(')') ? -1 * parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.Rent.toString().replace(/[^0-9.-]/g, ''));
            this.totalSalesTax += res.SalesTax.toString().includes('(') && res.SalesTax.toString().includes(')') ? -1 * parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.SalesTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalSecondLeveltax += res.CityTax.toString().includes('(') && res.CityTax.toString().includes(')') ? -1 * parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, '')) : parseFloat(res.CityTax.toString().replace(/[^0-9.-]/g, ''));
            this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
            this.getDiscounts();
            newbooths.push(JSON.parse(JSON.stringify(ob)));
            this.selectedBooths = this.selectedBooths.concat(newbooths);
          }, err => {
            this.loaderIcon = Promise.resolve(false);
          }));
          this.isdirty = true;
          this.isFormDirty.emit(true);
        });
      }

      this.loaderIcon = Promise.resolve(false);
      this.refreshAddBooth();
    }
  }

  closeLayoutForm() {
    this.displayLayout = false;
  }

  getMarketVendorSettings(){
    this.loaderIcon = Promise.resolve(true);
    const sub = this.vendorService.getMarketVendorSettings(this.vendorId, this.marketId).subscribe((res: any) => {
      var response = res.body;
      if (response) {
        this.exemptResale = response.ExemptResale;
        this.resaleNo = response.Resale;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  getVendorBusinessLicenseSettings(){
    this.loaderIcon = Promise.resolve(true);
    const sub = this.vendorService.getVendorBusinessLicenseSettings(this.vendorId, this.marketId).subscribe((res: any) => {
     if (res) {
        this.businessLicenseNumber = res.LicenseNumber;
        this.businessLicenseExpiryDate = res.ExpiryDate;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  AddBoothsOnGridOnValidationSucess() {
    var addToGrid = false;
    var closeModalPopup = false;
    const modal = this.modalResaleNo.nativeElement;
    if (this.resaleNoDisplay) {
      if (!this.resaleNo || this.resaleNo.trim() === '') {
        Swal.fire({
          title: $localize`Warning`,
          text: $localize`Enter the resale number`,
          icon: "warning",
          confirmButtonText: $localize`Ok`,
        });
      }
      else {
        addToGrid = true;
        closeModalPopup = true;
        this.resaleOptionSelected = true;
      }

      if (closeModalPopup) {
        if (modal.classList.contains('show')) {
          modal.style.display = 'none';
          modal.classList.remove('show');
        }
      }

      if (addToGrid) {
        const obj = {
          VendorID: this.vendorId,
          ExemptResaleNo: false,
          ExemptResaleReason: "",
          ResaleNo: this.resaleNo,
          MarketId: this.marketId
        }

          this.subscriptions.push(this.rentalService.UpdateVendorResaleInfo(obj).subscribe((res: any) => {
          }));
      }
    }
    else{
      addToGrid = true;
    }

    if (addToGrid) {
      if (this.BusinessLicenseNumberRequired && this.marketDayCountForResale > this.DaysSellWithoutBusiLicNumber) {
        var addToGrid = false;
        var closeModalPopup = false;
        if ((!this.businessLicenseNumber || this.businessLicenseNumber.trim() === '') || !this.businessLicenseExpiryDate) {
          this.ResaleOrBusinessHeader = "Business License Number"
          const modal = this.modalResaleNo.nativeElement;
          this.resaleNoDisplay = false;   
          this.businessLicenseDisplay = true;
          modal.classList.add('show');
          if (modal.classList.contains('show')) {
            modal.style.display = 'block';
            this.ResaleOrBusinessHeader = "Business License Number"                  
          }
        }
        else if (this.getDateFromDateTime(new Date(this.businessLicenseExpiryDate)) < this.getDateFromDateTime(new Date())) {
          this.closeResaleModal();
          Swal.fire({
            text: $localize`Business License is expired! Please contact the market.`,
            icon: "warning",
            confirmButtonText: $localize`Ok`,
          });
        }
        else {
          addToGrid = true;
          this.closeResaleModal();
          if (this.businessLicenseDisplay) {
              const obj = {
                VendorID: this.vendorId,
                LicenseNameId: 1,
                LicenseName: "",
                ExpiryDate: new Date(this.businessLicenseExpiryDate),
                LicenseNumber: this.businessLicenseNumber
              }

              this.subscriptions.push(this.rentalService.updateVendorBusinessLicense(this.marketId, obj).subscribe((res: any) => {
              }));
          }
          this.addSelectedBoothsToGrid();
        }       
      }
      else {
        addToGrid = true;
        closeModalPopup = true;
        this.addSelectedBoothsToGrid();
      }
    }
  }

  getDateFromDateTime(date){
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  closeResaleModal(){
    const modal = this.modalResaleNo.nativeElement;
    if (modal.classList.contains('show')) {
      modal.style.display = 'none';
      modal.classList.remove('show');
    }
  }

  loadSelectedBooths() {     
    if (this.sharedDataService.paymentSharedData.DailyRentalBooth.length > 0) {
      this.isdirty = true;
      this.isFormDirty.emit(true);
      this.sharedDataService.paymentSharedData.DailyRentalBooth.forEach((item) => {
        var newbooths = [];
        var ob = {
          DailyRentalId: item.DailyRentalId,
          BoothType: item.BoothType,
          BoothNumber: item.BoothNumber,
          BoothTypeId: item.BoothTypeId,
          Product: item.ProductId,
          OtherItemCharge: this.datePipe.transform(item.OtherItemCharge),
          OtherItemName: item.OtherItem,
          RentalDay: this.datePipe.transform(item.RentalDay, 'MM/dd/yyyy'),
          Rent: this.customCurrency.transform(item.RentalCharge),
          SalesTax: this.customCurrency.transform(item.SalesTax),
          CityTax: this.customCurrency.transform(item.SecLevelTax),
          NewBooth: true
        };
        newbooths.push(JSON.parse(JSON.stringify(ob)));
        this.selectedBooths = this.selectedBooths.concat(newbooths);
      });
    //  this.gridDailyRental.refresh();
    }
    if (this.sharedDataService.paymentSharedData.DailyRentalOtherCharges.length > 0) {
      this.isdirty = true;
      this.isFormDirty.emit(true);
      this.sharedDataService.paymentSharedData.DailyRentalOtherCharges.forEach((el) => {
        var newItem =[];
        var ob = {
          Id: el.OtherItemId,
          OtherItem: el.OtherItemName,
          OtherItemCharge: this.customCurrency.transform(el.OtherCharge),
          Quantity: el.Quantity,
          OtherItemtotal: this.customCurrency.transform(el.OtherItemCharge),
          RentalDay: this.datePipe.transform(el.RentalDay, "MM/dd/YYYY"),
          SalesTax: this.customCurrency.transform(el.SalesTax.toString()),
          CityTax: this.customCurrency.transform(el.SecLevelTax.toString()),
          BoothNo: el.BoothNumber,
          Reference: el.Reference,
          NewItem: true
        }
        newItem.push(JSON.parse(JSON.stringify(ob)));

        if (newItem.length > 0) {
          this.selectedOtherItems = this.selectedOtherItems.concat(newItem);
        }
      });
   //   this.gridOtherRental.refresh();
    }

    this.totalRentalCharge = parseFloat(this.sharedDataService.paymentSharedData.RentalCharge);
    this.totalOtherRentalCharge = parseFloat(this.sharedDataService.paymentSharedData.OtherCharge.toString());
    this.totalSalesTax = parseFloat(this.sharedDataService.paymentSharedData.SalesTax.toString());
    this.totalSecondLeveltax = parseFloat(this.sharedDataService.paymentSharedData.SecLevelTax.toString());
    this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
    this.sharedDataService.paymentSharedData = "";
  }

  deleteSelectedBooth(data) {
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want to delete the selected Booth?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then((result) => {
      if (result.value) {
        const index = this.selectedBooths.findIndex(selectedBooth => selectedBooth.BoothNumber === data.BoothNumber && selectedBooth.RentalDay == data.RentalDay);
        if (index !== -1) {
          this.selectedBooths.splice(index, 1);
        }

        const temp = {
          TempRecordNo: this.tempRecordNo.toString(),
          BoothNumber: data.BoothNumber,
          StartDate: new Date(data.RentalDay),
          RentalType: 1
        }
        this.subscriptions.push(
          this.rentalService.deleteFromTemp(temp).subscribe((res: any) => {
          })
        );
        this.totalRentalCharge -= data.Rent.includes('(') && data.Rent.includes(')') ? -1 * parseFloat(data.Rent.replace(/[^0-9.-]/g, '')) : parseFloat(data.Rent.replace(/[^0-9.-]/g, ''));
        this.totalSalesTax -= data.SalesTax.includes('(') && data.SalesTax.includes(')') ? -1 * parseFloat(data.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax -= data.CityTax.includes('(') && data.CityTax.includes(')') ? -1 * parseFloat(data.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.gridDailyRental.refresh();
        this.getDiscounts();
      }
    });
  }

  deleteOtherRentalCharges(data) {
    Swal.fire({
      title: $localize`Confirm`,
      text: $localize`Are you sure you want to delete the selected Other Charges?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: $localize`Yes`,
      cancelButtonText: $localize`No`,
    }).then((result) => {
      if (result.value) {
        const index = this.selectedOtherItems.findIndex(oth => oth.Id === data.Id && oth.RentalDay == data.RentalDay && oth.NewItem);
        if (index !== -1) {
          this.selectedOtherItems.splice(index, 1);
        }

        var selectedItems = this.otherRentalCharges.findIndex(item => item.Id == data.Id);
        if (selectedItems != -1) {
          this.subscriptions.push(this.rentalService.getOtherRentalItems(this.marketId).subscribe((response) => {
            if (response) {
              const masterChargeRecord = response.filter(item => item.OtherItemId == data.Id);
              if (masterChargeRecord) {
                this.otherRentalCharges[selectedItems].Quantity = 0;
                this.otherRentalCharges[selectedItems].othChgBooths = "none";
                this.otherRentalCharges[selectedItems].OtherItemCharge = this.customCurrency.transform(masterChargeRecord[0].OtherItemCharge);
                this.otherRentalCharges[selectedItems].OtherItemtotal = this.customCurrency.transform('0');
                this.otherRentalCharges[selectedItems].Reference = ""
              }
            }
          }, (err) => {
          }));
        }

        this.totalOtherRentalCharge -= data.OtherItemtotal.includes('(') && data.OtherItemtotal.includes(')') ? -1 * parseFloat(data.OtherItemtotal.replace(/[^0-9.-]/g, '')) : parseFloat(data.OtherItemtotal.replace(/[^0-9.-]/g, ''));
        this.totalSalesTax -= data.SalesTax.includes('(') && data.SalesTax.includes(')') ? -1 * parseFloat(data.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.SalesTax.replace(/[^0-9.-]/g, ''));
        this.totalSecondLeveltax -= data.CityTax.includes('(') && data.CityTax.includes(')') ? -1 * parseFloat(data.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(data.CityTax.replace(/[^0-9.-]/g, ''));
        this.totalCharge = this.totalRentalCharge + this.totalOtherRentalCharge + this.totalSalesTax + this.totalSecondLeveltax;
        this.gridOtherRental.refresh();
        this.getDiscounts();
      }
    });
  }

  disableSavePayment(){
      var vendorBalance = this.priorBalance + this.totalCharge;
      if (vendorBalance > 0){
        return false
      }
      else
        return true
  }

  getDiscounts() {
    var totalRentalCharge = this.totalRentalCharge;
    var totalSalesTax = this.totalSalesTax;
    var totalSecondLeveltax = this.totalSecondLeveltax;
    var totalOtherRentalCharge = this.totalOtherRentalCharge;
    var priorBalance = 0;
    var totalChargeWithDue = this.priorBalance + (totalRentalCharge)
      + (totalSalesTax)
      + (totalSecondLeveltax)
      + (totalOtherRentalCharge)

    var totalCharge = (totalRentalCharge) + (totalSalesTax) + (totalSecondLeveltax) + (totalOtherRentalCharge)
    //   totalCharge = totalCharge.toString().includes('(') && totalCharge.toString().includes(')') ? -1 * parseFloat(totalCharge.toString().replace(/[^0-9.-]/g, '')) : parseFloat(totalCharge.toString().replace(/[^0-9.-]/g, ''));

    if (this.vendorDiscountPlans.length > 0 && totalCharge > 0) {
      var discountTotalAmount = 0;
      var totalAmountForDiscount = 0;
      for (const disc of this.vendorDiscountPlans) {
        disc.CheckAll = disc.AutoSelected;
        if (disc.CheckAll && disc.Daily) {
          var currentDiscountValue = 0;
          if (!disc.BalanceDue || (disc.BalanceDue && priorBalance <= 0)) {
            if (!disc.IsExceedAmount || (disc.IsExceedAmount && totalCharge > disc.ExceedAmount)) {
              const discountValue = disc.DiscountValue;
              totalAmountForDiscount = totalRentalCharge
              if (disc.OtherItems) {
                totalAmountForDiscount += totalOtherRentalCharge
              }
              if (disc.DiscountType == 'Percentage') {
                discountTotalAmount += totalAmountForDiscount * (discountValue / 100);
                currentDiscountValue = totalAmountForDiscount * (discountValue / 100);
              }
              else {
                discountTotalAmount += discountValue;
                currentDiscountValue = discountValue;
              }
            }
            else {
              disc.CheckAll = false;
            }
          }
          else {
            disc.CheckAll = false;
          }
          disc.AppliedDiscount = this.customCurrency.transform(currentDiscountValue.toString());
        }
        else {
          disc.AppliedDiscount = this.customCurrency.transform("0");
        }

        this.totalDiscountCharge = discountTotalAmount.toString();
      }
    }
    else {
      // this.rentalForm.controls['vendorBalances'].patchValue({
      //   totalDiscountCharge: this.customCurrency.transform("0"),
      //   totalCharge: this.customCurrency.transform((totalChargeWithDue - 0).toString())
      // });
    }
  }

  cancelRental() {
    if (this.isdirty) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Unsaved changes will be lost. Are you sure you want to cancel?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.isFormDirty.emit(false);
          this.isCancelClicked.emit('1');
        } else {
          //do nothing
        }
      });
    } else {
      this.isFormDirty.emit(false);
      this.isCancelClicked.emit('1');
    }
  }

  rentOnlineNextTab(){
    this.saveRentalWithPayment();
    this.bookingFormCompleted.emit(true);
  }

  saveRentalWithPayment(){
    this.isdirty = false;
    this.isFormDirty.emit(false);
    var bookingReceipt = this.selectedBooths.some(e=>e.NewBooth == true) || this.selectedOtherItems.some(e=>e.NewItem == true);
    var paymentDueDate = null;
    var totalDiscountCharge = 0
    
    var obj = {
      RentalType: 1,
      VendorId: this.vendorId,
      PriorBalance:this.priorBalance,
      RentalCharge: this.totalRentalCharge,
      SalesTax: this.totalSalesTax,
      SecLevelTax: this.totalSecondLeveltax,
      OtherCharge: this.totalOtherRentalCharge,
      TotalBalanceDue: this.totalCharge,
      RentalNotes: "",
      RentalMethod: "",
      DailyRentalBooth: [],
      DailyRentalOtherCharges: [],
      TempRecordNo: this.tempRecordNo,
      ReceiptSettings: "",
      PaymentOnly:!bookingReceipt,
      ExemptResaleNo:false,
      ExemptResaleReason: "",
      ResaleNo: this.resaleNo,
      PaymentDueDate: this.DisplayPayDueDate ? paymentDueDate : null,
      AppliedDiscountModel: [],
      DiscountAmount: this.totalDiscountCharge ?? 0,
      BookingFee: this.bookingFeeAmount ?? 0
    }

    var objSelectedBooth = [];
    if (this.selectedBooths.length > 0) {
      this.selectedBooths.forEach((item) => {
        if (item.NewBooth) {
          objSelectedBooth.push({
            DailyRentalId: item.DailyRentalId,
            BoothNumber: item.BoothNumber,
            BoothTypeId: item.BoothTypeId,
            ProductId: item.Product,
            BoothType: item.BoothType,
            RentalDay: new Date(item.RentalDay),
            RentalCharge: item.Rent.includes('(') && item.Rent.includes(')') ? -1 * parseFloat(item.Rent.replace(/[^0-9.-]/g, '')) : parseFloat(item.Rent.replace(/[^0-9.-]/g, '')),
            SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
            SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
          })
        }
      });
    }

    if (objSelectedBooth.length > 0)
      obj.DailyRentalBooth = objSelectedBooth;

    var objSelectedOtherCharge = [];
    if (this.selectedOtherItems.length > 0) {
      this.selectedOtherItems.forEach((item) => {
        if (item.NewItem) {
          objSelectedOtherCharge.push({
            OtherItemId: item.Id,
            OtherItemName: item.OtherItem,
            OtherItemCharge: item.OtherItemtotal.includes('(') && item.OtherItemtotal.includes(')') ? -1 * parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemtotal.replace(/[^0-9.-]/g, '')),
            Quantity: item.Quantity,
            RentalDay: new Date(item.RentalDay),
            BoothNumber: item.BoothNo,
            OtherCharge: item.OtherItemCharge.includes('(') && item.OtherItemCharge.includes(')') ? -1 * parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')) : parseFloat(item.OtherItemCharge.replace(/[^0-9.-]/g, '')),
            SalesTax: item.SalesTax.includes('(') && item.SalesTax.includes(')') ? -1 * parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTax.replace(/[^0-9.-]/g, '')),
            SecLevelTax: item.CityTax.includes('(') && item.CityTax.includes(')') ? -1 * parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')) : parseFloat(item.CityTax.replace(/[^0-9.-]/g, '')),
            Reference: item.Reference
          })
        }
      });
    }
    if (objSelectedOtherCharge.length > 0)
      obj.DailyRentalOtherCharges = objSelectedOtherCharge;

    var objDiscountData = [];
    if(this.vendorDiscountPlans.length > 0 && totalDiscountCharge > 0){
      this.vendorDiscountPlans.forEach((item) => {
        if(item.CheckAll){
          objDiscountData.push({
            PlanId: item.PlanSeqId,
            DiscountAmount:item.AppliedDiscount.includes('(') && item.AppliedDiscount.includes(')') ? -1 * parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')) : parseFloat(item.AppliedDiscount.replace(/[^0-9.-]/g, '')),
            OtherCharges: item.OtherItems,
            SalesTax: item.SalesTaxApplied,
            SecondLevelTax: item.SecondLevelTaxApplied,
            DiscountType:item.DiscountType==  DiscountType.Percentage ? "0" : "1",
            DiscountValue : item.DiscountValue,
            SalesTaxAmount:item.SalesTaxAmount.includes('(') && item.SalesTaxAmount.includes(')') ? -1 * parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SalesTaxAmount.replace(/[^0-9.-]/g, '')),
            SecondLevelTaxAmount:item.SecondLevelTaxAmount.includes('(') && item.SecondLevelTaxAmount.includes(')') ? -1 * parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, '')) : parseFloat(item.SecondLevelTaxAmount.replace(/[^0-9.-]/g, ''))
          })
        }
      });
    }
    if (objDiscountData.length > 0)
      obj.AppliedDiscountModel = objDiscountData;
    if (!this.newVendor) {
      this.sharedDataService.paymentSharedData = obj;
      var url = 'vendor-payments/' + this.marketId;
      this.router.navigate([url]);
    }
    else{
      this.rentalBookingData.emit(obj);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
