import { Component, OnInit } from '@angular/core';
import { ContactUsService } from 'src/app/services/ContactUs.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params } from '@angular/router';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  name: string;
  phone: string;
  email: string;
  comments: string;
  subject: string;
  marketId: any;
  firstNum: number;
  secondNum: number;

  constructor(private contactUsService: ContactUsService, private toastr: ToastrService, private route: ActivatedRoute, private marketInfoService: MarketInfoService) { }

  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.marketInfoService.setMarketId(this.marketId);
    });
    this.generateCaptcha();
  }
  onSubmit(): void {
    if (!this.name || !this.phone || !this.email || !this.comments || !this.subject) {
      this.toastr.error('Fill the mandatory fields.', 'Error', this.toasterConfig);
      return;
    }

    if (!this.validateCaptcha()) {
      this.toastr.error('Captcha verification failed.', 'Error', this.toasterConfig);
      return;
    }

    const contactUsForm = {
      MarketId: this.marketId,
      Name: this.name,
      Phone: String(this.phone),
      Email: this.email,
      Subject: this.subject,
      comments: this.comments
    };

    this.submitContactUsForm(contactUsForm);
  }


  submitContactUsForm(contactUsForm) {
    this.contactUsService.SubmitContactUs(contactUsForm)
      .subscribe(
        res => {
          if (res.Success) {
            this.toastr.success($localize`Submitted Unsuccessfully`, $localize`Success`, this.toasterConfig);

            this.resetForm();
          } else {
            this.toastr.error($localize`Submit Unsuccessful`, $localize`Error`, this.toasterConfig);
          }
        },
        err => {
          this.toastr.error(err.Message, $localize`Error`, this.toasterConfig);
        }
      );
  }

  generateCaptcha(): void {
    this.firstNum = Math.floor(Math.random() * 25) + 1;
    this.secondNum = Math.floor(Math.random() * 25) + 1;

    const firstNumElement = document.getElementById("firstNum");
    const secondNumElement = document.getElementById("secondNum");

    if (firstNumElement && secondNumElement) {
      firstNumElement.textContent = this.firstNum.toString();
      secondNumElement.textContent = this.secondNum.toString();
    }
  }

  validateCaptchaResult(event) {
    const key = event.key || event.keyCode;

    if (((event.keyCode >= 65 && event.keyCode <= 95) || event.keyCode >= 106 || (event.keyCode >= 48 && event.keyCode <= 57 && isNaN(event.key))) && event.keyCode != 32) {
      console.log(key);
      event.preventDefault();
    }
  }

  numberOnly(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  numberOnlyPaste(event: any) {
    const pastedInput = event.clipboardData.getData('text/plain');
    const pattern = /[0-9]/;

    if (!pattern.test(pastedInput)) {
      event.preventDefault();
    }
  }

  validateCaptcha(): boolean {
    const enteredResult = Number((<HTMLInputElement>document.getElementById("captchaResult")).value);
    const expectedResult = this.firstNum + this.secondNum;

    return enteredResult === expectedResult;
  }

  resetForm(): void {
    this.name = '';
    this.phone = '';
    this.email = '';
    this.comments = '';
    this.subject = '';
    const captchaResultInput = document.getElementById("captchaResult") as HTMLInputElement;
    captchaResultInput.value = '';
    this.generateCaptcha();
  }


}
