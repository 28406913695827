import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { IVendorInfo } from '../Shared/models/VendorModel';
import { MarketInfoService } from './MarketInfoService.service';
@Injectable({
    providedIn: 'root',
})

export class VendorService {
    private baseURL: string;
    constructor(private http: HttpClient,private marketInfoService: MarketInfoService
    ) {
        this.baseURL = environment.apiBaseUrl;
    }

    private vendorIdSubject = new BehaviorSubject<number | 0>(0);
    vendorId$ = this.vendorIdSubject.asObservable();

    setVendorId(id: number) {
        this.vendorIdSubject.next(id);
    }

    
    getVendorId() {
        return this.vendorIdSubject.asObservable();
    }

    public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    GetVendorTypes(marketId): Observable<any> {
        const params = new HttpParams().set('marketId', marketId.toString());
        return this.http.get<any>(
            `${this.baseURL}/General/GetVendorTypes`,
            { observe: 'response', params, headers: this.options.headers })
            .pipe(
                map(res => {
                    const result = res.body as any;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    SaveVendor(Vendor: IVendorInfo): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Vendor/AddVendor`;
        return this.http.post<any>(url, Vendor, options)
            .pipe(
                map(res => {
                    const response = res as any[];
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    RegisterVendorInfoValidation(Vendor: IVendorInfo): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Vendor/RegisterVendorInfoValidation`;
        return this.http.post<any>(url, Vendor, options)
            .pipe(
                map(res => {
                    const response = res as any[];
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    GetVendorById(vendorId: number,marketId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Vendor/GetVendorInfoById?vendorId=${vendorId}&marketId=${marketId}`,
   
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res as any;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    getMarketVendorSettings(id: number,marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/Vendor/GetMarketVendorSetting?vendorId=${id}&marketId=${marketId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const rentalSettings = res as any;
                    return rentalSettings;
                }),
                catchError(this.handleError)
            );
    }

    getVendorBusinessLicenseSettings(vendorId, marketId): Observable<boolean> {
        return this.http.get<any>(
            `${this.baseURL}/Vendor/GetVendorBusinessLicenseSettings?vendorId=${vendorId}&marketId=${marketId}`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    return res.body as any;
                }),
                catchError(this.handleError)
            );
    }

    SaveVendorLoginDetail(VendorLoginDetail: any): Observable<any> {
        var marketId = null;
        this.marketInfoService.getMarketId().subscribe(mrktId => {
            marketId = mrktId;
        });
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Vendor/SaveVendorLoginDetails/${marketId}`;
        return this.http.post<any>(url, VendorLoginDetail, options)
            .pipe(
                map(res => {
                    const response = res as any[];
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    VendorLoginDetailsValidation(VendorLoginDetail: any): Observable<any> {
        var marketId = null;
        this.marketInfoService.getMarketId().subscribe(mrktId => {
            marketId = mrktId;
        });
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Vendor/VendorLoginDetailsValidation/${marketId}`;
        return this.http.post<any>(url, VendorLoginDetail, options)
            .pipe(
                map(res => {
                    const response = res as any[];
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    SendRegistrationEmail(vendorId: number): Observable<any> {
        var marketId = null;
        this.marketInfoService.getMarketId().subscribe(mrktId => {
            marketId = mrktId;
        });
        return this.http.get<any>(
            `${this.baseURL}/Vendor/SendRegistrationEmail?vendorId=${vendorId}&marketId=${marketId}`,

            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res as any;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    GetDiscountPlan(vendorId,marketId): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/Vendor/GetDiscountPlan??vendorId=${vendorId}&marketId=${marketId}`,

            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res.body as any;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }    
   
    getCountries(): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/General/GetCountries`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Countries = res.body as any[];
                    return Countries;
                }),
                catchError(this.handleError)
            );
    }
   
    getUSStates(): Observable<any[]> {
        return this.http.get<any[]>(
          `${this.baseURL}/General/GetUSStates`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const states = res.body as any[];
                    return states;
                }),
                catchError(this.handleError)
            );
    }
   
    getVendorLoginSecurityQuestion(): Observable<any[]> {
        return this.http.get<any[]>(
            `${this.baseURL}/General/GetVendorLoginSecurityQuestion`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const states = res.body as any[];
                    return states;
                }),
                catchError(this.handleError)
            );
    }
   
    UpdateVendor(Vendor): Observable<any> {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
        const url = `${this.baseURL}/Vendor/UpdateVendorInfoForWebRental`;
        return this.http.post<any>(url, Vendor, options)
            .pipe(
                map(res => {
                    const response = res as any[];
                    return response;
                }),
                catchError(this.handleError)
            );
    }
}
