<form [formGroup]="rentalForm">
    <div *ngIf="!displayLayout">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-right">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 mblRightToLeft mt-1">
                        <label class="mt-2 mb-0">Select Layout Part</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 mblRightToLeft mt-2">
                        <select class="form-control" formControlName="marketParts">
                            <option *ngFor="let item of parts" [value]="item.PartId">{{item.PartDescription}}</option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-2 mblRightToLeft mt-2">
                        <button class="btn btn-primary py-2" (click)="openLayout()">Select Booth</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                <div class="card shadow mt-2">
                    <div class="card-header fw-700">
                        * Select Market Day(s)Here
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngIf="monday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkMonday" formControlName="marketWeekMonday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkMonday" i18n>
                                    Monday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="tuesday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkTuesday" formControlName="marketWeekTuesday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkTuesday" i18n>
                                    Tuesday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="wednesday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkWednesday" formControlName="marketWeekWednesday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkWednesday" i18n>
                                    Wednesday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="thursay">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkThursday" formControlName="marketWeekThursday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkThursday" i18n>
                                    Thursday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="friday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkFriday" formControlName="marketWeekFriday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkFriday" i18n>
                                    Friday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="saturday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkSaturday" formControlName="marketWeekSaturday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkSaturday" i18n>
                                    Saturday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="sunday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkSunday" formControlName="marketWeekSunday"
                                (change)="selectedMonthlyMarketDays()">
                                <label class="form-check-label ml-2 mt-1" for="chkSunday" i18n>
                                    Sunday
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card shadow mt-2">
                    <div class="card-header fw-700">
                        Monthly Market(s):
                    </div>
                    <div class="row mt-1" style="padding: .375rem .75rem;">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <label i18n>Rental Start Month</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <select class="form-control" formControlName="rentalStartMonth"
                                (change)="checkRentalMonths()">
                                <option *ngFor="let item of monthlyMarketDays;" [value]="item.RentalMonths">
                                    {{item.RentalMonths}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row mt-1" style="padding: .375rem .75rem;">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <label>Rental End Month</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <select class="form-control" formControlName="rentalEndMonth"
                                (change)="checkRentalMonths()">
                                <option *ngFor="let item of monthlyMarketDays;" [value]="item.RentalMonths">
                                    {{item.RentalMonths}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-1" style="padding: .375rem .75rem;">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <label i18n>Rental Start Date</label>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                            <select class="form-control" formControlName="monthlyRentalStartDates">
                                <option *ngFor="let item of monthlyRentalStartDates;" [value]="item.startDate">
                                    {{item.startDate}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-right">
                <div class="vendorDetails mt-2">
                    <ejs-grid #gridMonthlyRental [dataSource]='selectedMonthlyBooths' (created)="created($event)"
                        class="Table_Height_store table-striped table-ipad" [gridLines]='lines' height="150"
                        [allowSelection]="true" allowSorting='true' [sortSettings]='sortOptions' [textWrapSettings]="textWrapSettings" allowTextWrap="true">
                        <e-columns>
                            <e-column field='BoothNumber' headerText='Booth#' textAlign='left' width=130>
                            </e-column>
                            <e-column field='BoothDescription' headerText='Booth Description' textAlign='left' width=210>
                            </e-column>
                            <e-column field='Product' headerText='Product' textAlign='left' width=210>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <select class="form-control" [(ngModel)]="data.Product"
                                            [ngModelOptions]="{standalone: true}" (change)="productChange(data)">
                                            <option *ngFor="let item of vendorProducts" [value]="item.ProductId">
                                                {{item.ProductName}}</option>
                                        </select></span>
                                </ng-template>
                            </e-column>
                            <e-column field='RentStartPeriod' textAlign='left' headerText='Rent Start Period' width=210>
                            </e-column>
                            <e-column field='RentEndPeriod' textAlign='left' headerText='Rent End Period' width=210>
                                <ng-template #template let-data>
                                    <span class="dataName"
                                        (click)="rentalExtension(RentalExtension,data)">{{data.RentEndPeriod}}</span>
                                </ng-template>
                            </e-column>                           
                            <e-column field='Rent' headerText='Rent' textAlign='left' width=150>
                            </e-column>
                            <e-column field='SalesTax' headerText='Sales Tax' textAlign='left' width=170>
                            </e-column>
                            <e-column field='CityTax' headerText='Sec Level Tax' textAlign='left' width=170>
                            </e-column>                           
                            <e-column field='RentalEndDate' headerText='Rental End Date' textAlign='left' width=190>
                            </e-column>
                            <e-column headerText='Delete' field="Delete" textAlign='left' width='150'>
                                <ng-template #template let-data>
                                    <span class="Delete">
                                        <button class="colDelete" (click)="deleteSelectedBooth(data)"><img
                                                src="../assets/images/Delete_Icon.svg" class="img-fluid"
                                                style="max-width: 80% !important;" /></button>
                                    </span>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
                <div class="row" *ngIf="AllowRentingOtherItems">
                    <div class="col-12 text-right" style="margin-top: 18px">
                        <button class="btn btn-primary" data-toggle="modal"
                        data-target="#modalMonthlyOtherCharges">Select Other Item</button>
                    </div>
                </div>
                <div class="vendorDetails mt-2" *ngIf="AllowRentingOtherItems">
                    <ejs-grid #gridOtherRental [dataSource]='selectedOtherItems'
                        class="Table_Height_store table-striped table-ipad" height='150' allowSorting='true'
                        [sortSettings]='sortOtherItemsOptions'>
                        <e-columns>
                            <e-column field='OtherItem' headerText='Item' textAlign='left' width=180>
                            </e-column>
                            <e-column field='Recurring' headerText='Rec' textAlign='left' width=130>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <div class="checkbox-wrapper mt-2">
                                            <label class="pure-material-checkbox ">
                                                <input type="checkbox" class="checkBoxClass"
                                                    [ngModelOptions]="{standalone: true}" [(ngModel)]="data.Recurring"
                                                    (change)="updateOtherItem(data)">
                                                <span i18n></span>
                                            </label>
                                        </div>
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column field='OtherItemCharge' headerText='Charge' textAlign='left' width=140>                               
                            </e-column>
                            <e-column field='Quantity' headerText='Qty' textAlign='left' width=110>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <input type="text" class="form-control pr-1" style="text-align: center;"
                                            [(ngModel)]="data.Quantity"
                                            [ngModelOptions]="{standalone: true}" (blur)="updateOtherItem(data)"
                                            (keydown)="onCheckNumKeyPress($event)">
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column field='BoothNo' headerText='Booth#' textAlign='left' width=100>
                            </e-column>
                            <e-column field='RentalMonth' headerText='Month' width=240 textAlign='left'>
                            </e-column>
                            <e-column field='OtherItemtotal' headerText='Total' textAlign='left' width=140>
                            </e-column>
                            <e-column field='SalesTax' headerText='Sales Tax' textAlign='left' width=140>
                            </e-column>
                            <e-column field='CityTax' headerText='Sec Level Tax' textAlign='left' width=140>
                            </e-column>
                            <e-column field='Reference' headerText='Reference' textAlign='left' width=160>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <input type="text" class="form-control" (blur)="updateOtherItem(data)"
                                            [(ngModel)]="data.Reference" [ngModelOptions]="{standalone: true}">
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column headerText='Delete' field="Delete" textAlign='left' width='150'>
                                <ng-template #template let-data>
                                    <span class="Delete">
                                        <button class="colDelete" (click)="deleteOtherRentalCharges(data)"><img
                                                src="../assets/images/Delete_Icon.svg" class="img-fluid"
                                                style="max-width: 80% !important;" /></button>
                                    </span>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>

                <div class="vendorDetails1 mt-2">
                    <div class="float-right bottom_button mt-sm-2 w-100 d-flex" *ngIf="!newVendor">
                        <button type="button" i18n class="btn btnCancel mr-1" (click)="cancelRental()">Cancel</button>
                        <button type="button" i18n class="btn btn-primary" [disabled]="disableSavePayment()" (click)="saveRentalWithPayment()">Pay</button>
                    </div>
                    <div class="float-right bottom_button mt-sm-2 w-100 d-flex" *ngIf="newVendor">
                        <button type="button" i18n class="btn btnCancel mr-1" (click)="cancelRental()">Cancel</button>
                        <button type="button" i18n class="btn btn-primary" [disabled]="disableSavePayment()" (click)="rentOnlineNextTab()">Next</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal for Adding Other Charges -->
        <div class="modal fade bd-example-modal-xl" #modalMonthlyOtherCharges id="modalMonthlyOtherCharges"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content contentNewBooths">
                    <div class="modal-header modal-headerNewBooths">
                        <h5 class="modal-title" id="exampleModalCenterTitle" i18n>Other Charges</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body modal-bodyNewBooths" formGroupName="otherItemsScreen">
                        <div class="row mt-0">
                            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-left">
                                <label i18n>Rental Month</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                                <select class="form-control" formControlName="otherChargeMarketMonth"
                                    (change)="getBoothsAndItemsForOtherCharges()">
                                    <option *ngFor="let item of monthlyMarketDays;" [value]="item.RentalMonths">
                                        {{item.RentalMonths}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-left">
                                <label i18n>Search Value</label>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                                <input type="text" class="form-control" formControlName="otherItemSearchText"
                                    (keyup)="searchOtherItem()">
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left">
                                
                            </div>
                        </div>
                        <div class="row" style="height: 46vh;overflow-y: scroll;">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-0">
                                <div class="availableBoothsHeading pt-1 pb-1">
                                    <label i18n>Other Charges</label>
                                </div>
                                <div class="scroll_outer">
                                        <table class="table table-striped table-hover followup-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th class="text-left tblAvailableBooths" width="40"></th>
                                                    <th class="text-left tblAvailableBooths" width="200" i18n>Items</th>
                                                    <th class="text-left tblAvailableBooths" width="40" i18n>Rec</th>
                                                    <th class="text-left tblAvailableBooths" width="100"
                                                        *ngIf="displayOtherChargeByBooth" i18n>Booth#</th>
                                                    <th class="text-left tblAvailableBooths" width="120" i18n>Charges
                                                    </th>
                                                    <th class="text-left tblAvailableBooths" width="100" i18n>Qty</th>
                                                    <th class="text-left tblAvailableBooths" width="120" i18n>Total</th>
                                                    <th class="text-left tblAvailableBooths" width="160" i18n>Reference
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of otherRentalCharges">
                                                    <td class="text-left" style="width: 10%;">
                                                        <label class="pure-material-checkbox">
                                                            <input type="checkbox" [checked]="isDateSelected(item)"
                                                                (change)="setDefaultRentalItem($event,item)"
                                                                [(ngModel)]="item.isOtherItemChecked"
                                                                [ngModelOptions]="{standalone: true}">
                                                            <span></span>
                                                        </label>
                                                    </td>
                                                    <td class="text-left" style="width: 35%;">{{item.OtherItem}}</td>
                                                    <td class="text-left">
                                                        <div class="checkbox-wrapper mt-2" style="width: 15%;">
                                                            <label class="pure-material-checkbox ">
                                                                <input type="checkbox" class="checkBoxClass"
                                                                    [(ngModel)]="item.Recurring"
                                                                    [ngModelOptions]="{standalone: true}"
                                                                    (change)="otherItemUpdate(item)"
                                                                    [disabled]="!item.otherItemChecked">
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td class="text-left tblAvailableBooths" style="width: 35%;"
                                                        *ngIf="displayOtherChargeByBooth">
                                                        <select class="form-control" [(ngModel)]="item.othChgBooths"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="otherItemUpdate(item)"
                                                            [disabled]="!item.otherItemChecked">
                                                            <option value="none">None</option>
                                                            <option *ngFor="let booth of boothsForOtherCharge"
                                                                [value]="booth.BoothNumber">{{booth.BoothNumber}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td class="text-left" style="width: 30%;">
                                                        <input type="text" class="form-control text-right pr-1"
                                                            [disabled]="!item.otherItemChecked  || !overrideOtherChargesRent"
                                                            [(ngModel)]="item.OtherItemCharge"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (change)="otherItemUpdate(item)">
                                                    </td>
                                                    <td class="text-left" style="width: 25%;"><input type="number"
                                                            class="form-control" [(ngModel)]="item.Quantity"
                                                            [disabled]="!item.otherItemChecked"
                                                            [ngModelOptions]="{standalone: true}" min="1"
                                                            (ngModelChange)="otherItemUpdate(item)"
                                                            onkeydown="return false">
                                                    </td>
                                                    <td class="text-right" style="width: 20%;">{{item.OtherItemtotal}}
                                                    </td>
                                                    <td class="text-left" style="width: 35%;">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="item.Reference"
                                                            [disabled]="!item.otherItemChecked"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (keyup)="otherItemUpdate(item)">
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer pt-0 pb-2">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 MblRightToLeft mt-2">
                                <button class="btn btn-primary mr-1" (click)="addOtherCharges()" data-dismiss="modal"
                                    aria-label="Close" i18n>OK</button>
                                <button class="btn btn-outline-primary" data-dismiss="modal" aria-label="Close"
                                    i18n>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ends Modal for Adding Other Charges -->
    </div>
</form>

<div *ngIf="displayLayout">
    <app-rentallayout-online [marketId]="marketId" [layoutParts]="layoutParts" [LayoutData]="MonthlyLayoutData"
        rentalType="M" (selectedBoothsFromLayout)="selectedBoothsFromLayout($event)" (CloseLayout)="closeLayoutForm()"
        (removeBoothCharges)="removeBoothCharges($event)"></app-rentallayout-online>
</div>

<!-- Modal for Resale Number -->
<div class="modal fade bd-example-modal-xl" #modalResaleNo id="modalResaleNo" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header modal-headerNewBooths">
                <h5 class="modal-title" id="exampleModalCenterTitle" i18n>{{ResaleOrBusinessHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeResaleModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left" *ngIf="resaleNoDisplay">
                        <div class="checkbox-wrapper mt-2">
                            <label class="pure-material-checkbox">Resale Number is required!</label>
                        </div>
                        <hr class="my-3">
                        <div class="col-md-12 col-8 mt-2">
                            <label for="resaleNumberInput">Resale Number</label>
                            <input type="text" id="resaleNumberInput" class="form-control" [(ngModel)]="resaleNo" maxlength="30" />
                        </div>
                    </div>
                
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left" *ngIf="businessLicenseDisplay">
                        <div class="checkbox-wrapper mt-2">
                            <label class="pure-material-checkbox">Business License Number is required!</label>
                        </div>
                        <hr class="my-3">
                        <div class="col-md-12 col-8 mt-2">
                            <label for="businesLicenseNumberInp">Business License Number</label>
                            <input type="text" id="businesLicenseNumberInp" class="form-control" [(ngModel)]="businessLicenseNumber"
                                maxlength="30" />
                        </div>
                        <div class="col-md-12 col-8 mt-2">
                            <label for="businesLicenseExpDateInp">Business Expiry Date</label>
                            <ejs-datepicker format='MM/dd/yyyy' [allowEdit]=false id="businesLicenseExpDateInp"
                                [(ngModel)]="businessLicenseExpiryDate" i18n-placeholder="Business License Expiry Date"
                                placeholder="Business License Expiry Date" [min]="minExpiryDate"></ejs-datepicker>
                        </div>
                    </div>
                </div>

                <div class="modal-footer d-block pt-1 pb-2">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-1">
                            <button class="btn btn-primary mr-1" data-dismiss="modal" aria-label="Close" i18n
                                (click)="AddBoothsOnGridOnValidationSucess()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal for Resale Number -->
