import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Subscription } from 'rxjs';
import { MarketInfoService } from 'src/app/services/MarketInfoService.service';
import { VendorService } from 'src/app/services/Vendor.service';
import { SharedDataService } from 'src/app/Shared/SharedData.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rent-online',
  templateUrl: './rent-online.component.html',
  styleUrls: ['./rent-online.component.scss']
})
export class RentOnlineComponent implements OnInit, OnDestroy {
  showrentOnline: boolean = true;
  showContactInfo: boolean = false;
  showLoginInfo:boolean=false;
  showBookings:boolean=false;
  showReviewRegistration:boolean=false;
  IsVendorLogin:boolean=true;
  pageName:string="Rent Online";
  @ViewChild('btnRentOnline') btnRentOnline!: ElementRef;
  @ViewChild('ContactInfo') ContactInfo!: ElementRef;
  @ViewChild('btnLoginInfo') btnLoginInfo!: ElementRef;
  @ViewChild('btnBookings') btnBookings!: ElementRef;
  @ViewChild('btnRegistration') btnRegistration!: ElementRef;
  marketId;
  rentalForm: FormGroup;  
  public loaderIcon: Promise<boolean>;  
  AllowBookingsCw = false;
  AllowBookingsWeekly = false;
  AllowBookingsCm = false;
  AllowBookingsMonthly = false;
  AllowBookingsDaily = false;
  private subscriptions: Subscription[] = [];
  defaultRentalTypeSelected;
  marketName = "";
  stateList = [];
  countryList = [];
  vendorId;
  vendorInfo;
  showStateDropDown;
  securityQuestion = [];
  showCellPhoneError = false;
  showWorkPhoneError = false;
  vendorMarketID = "";
  selected='1';
  toasterConfig = {
    closeButton: true,
    timeOut: 4500,
    easing: 'ease-out',
    progressBar: true,
    positionClass: 'toast-top-right',
    tapToDismiss: true,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'slideUp'
  };
  vendorName;
  public PhoneNumberMask: String = '000-000-0000';
  public ZipCodeMask: String = '00000-0000';
  emailIdsPattern = '^(([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,}))([\\s]?[,;]{1}[\\s]?([\\w+-.%]+)@([\\w-.]+)\\.([A-Za-z]{2,})){0,4}$';
  constructor(private renderer: Renderer2,
    private formbuilder: FormBuilder,
    private vendorService:VendorService,
    private route: ActivatedRoute,
    private sharedDataService: SharedDataService,
    public router: Router,
    private toastr: ToastrService,
    private marketInfoService: MarketInfoService,
  ) {
    this.rentalForm = this.formbuilder.group({
      rentalTypes: ["1"],
      contactInfoForm: this.formbuilder.group({
        firstName: ['',[Validators.required]],
        lastName: ['',[Validators.required]],
        companyName: [''],
        address: ['',[Validators.required]],
        city: ['',[Validators.required]],
        state: ['',[Validators.required]],
        country: ['',[Validators.required]],
        postalCode: ['',[Validators.required]],
        workPhone: ['',[Validators.minLength(10),Validators.maxLength(12)]],
        cellPhone: ['',[Validators.required,Validators.minLength(10),Validators.maxLength(12)]],
        email: [['', this.noWhitespaceValidator, Validators.pattern(this.emailIdsPattern)],[Validators.required]],
      }),
      securityQuestions: [''],
      bookingRentalTypes: ['']
    })
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.marketId = params['marketId'];
      this.vendorId= params['vendorId'];
    });
    this.getStatesAndCountries();
    this.getMarketDetails();
    this.getWebRentalSetting();      

    this.rentalForm.get('bookingRentalTypes')?.valueChanges.subscribe(value => {
      this.refreshBookingDetails();
    });
  }

  public noWhitespaceValidator(control: FormControl) {
    if ((control.value !== '') && (control.value !== null)) {
      if (control.value) {
        const isWhitespace = (control.value).trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
      }
    }
  }

  restrictSpace(eve) {
    if (eve.which === 32) {
      eve.preventDefault();
    }
  }
  
  refreshBookingDetails() {
    if (this.showBookings) {
      this.showBookings = false;
      setTimeout(() => {
        this.showBookings = true;
      }, 0);
    }
  }

  onKeyUp(field: string) {
    const phoneControl = this.rentalForm.controls['contactInfoForm'].get(field);
    if (field === 'cellPhone') {
      this.showCellPhoneError = phoneControl && phoneControl.invalid && phoneControl.touched;
    } else if (field === 'workPhone') {
      this.showWorkPhoneError = phoneControl && phoneControl.invalid && phoneControl.touched;
    }
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', '#DDEBFC');
  }

  getMarketDetails() {
    this.loaderIcon = Promise.resolve(true);
    const sub = this.marketInfoService.getMarketDetails(this.marketId).subscribe((res: any) => {
      if (res) {
          this.marketName = res.MarketName;
      }
      this.loaderIcon = Promise.resolve(false);
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
    this.subscriptions.push(sub);
  }

  onCountryChange() {    
    this.showStateDropDown = true;
    if (this.rentalForm.value['country'] == 2) {
      this.showStateDropDown = false;
    }    
  }

  getStatesAndCountries() {
    forkJoin({
      resCountries: this.vendorService.getCountries(),
      resStates: this.vendorService.getUSStates(),
      resSecurityQuestion :this.vendorService.getVendorLoginSecurityQuestion()
    }).subscribe(({ resCountries, resStates,resSecurityQuestion }) => {
      if (resCountries) {
        this.countryList = resCountries;
      }
      if (resStates) {
        this.stateList = resStates;
      }
      if(resSecurityQuestion){
        this.securityQuestion = resSecurityQuestion;
      }
      this.getVendorInfobyId();
    }, err => {
      this.loaderIcon = Promise.resolve(false);
    });
  }

  getVendorInfobyId() {
    this.vendorService.GetVendorById(this.vendorId, this.marketId).subscribe((response) => {
      if (response) {
        this.vendorInfo = response.body;
        this.vendorMarketID = this.vendorInfo.MarketVendorId;
        this.vendorName = this.vendorInfo.FirstName + " " + this.vendorInfo.LastName
        this.rentalForm.controls['contactInfoForm'].get('firstName').setValue(this.vendorInfo.FirstName);       
         this.rentalForm.controls['contactInfoForm'].get('lastName').setValue(this.vendorInfo.LastName);
         this.rentalForm.controls['contactInfoForm'].get('companyName').setValue(this.vendorInfo.CompanyName);
         this.rentalForm.controls['contactInfoForm'].get('address').setValue(this.vendorInfo.Address);
         this.rentalForm.controls['contactInfoForm'].get('city').setValue(this.vendorInfo.City);
         this.rentalForm.controls['contactInfoForm'].get('state').setValue(this.vendorInfo.State);
         this.rentalForm.controls['contactInfoForm'].get('country').setValue(this.vendorInfo.CountryCode);
         this.rentalForm.controls['contactInfoForm'].get('postalCode').setValue(this.vendorInfo.ZipCode);
         this.rentalForm.controls['contactInfoForm'].get('workPhone').setValue(this.vendorInfo.WorkPhone);
         this.rentalForm.controls['contactInfoForm'].get('cellPhone').setValue(this.vendorInfo.CellPhone);
         this.rentalForm.controls['contactInfoForm'].get('email').setValue(this.vendorInfo.Email);
        this.onCountryChange();
      }
    }, (err) => {
    });
  }

  validateContactForm() {
    if (this.rentalForm['controls'].contactInfoForm.invalid) {
      return true
    } else {
      return false
    }
  }

  UpdateVendor(): void {
    this.loaderIcon = Promise.resolve(true);
    const vendorinfo = {
      VendorId: this.vendorId,
      FirstName:  this.rentalForm.controls['contactInfoForm'].get('firstName').value,    
      LastName :  this.rentalForm.controls['contactInfoForm'].get('lastName').value,
      CompanyName :  this.rentalForm.controls['contactInfoForm'].get('companyName').value,
      Address :  this.rentalForm.controls['contactInfoForm'].get('address').value ,   
      City :  this.rentalForm.controls['contactInfoForm'].get('city').value,    
      State :  this.rentalForm.controls['contactInfoForm'].get('state').value,
      CountryCode :  this.rentalForm.controls['contactInfoForm'].get('country').value,
      CellPhone :  this.rentalForm.controls['contactInfoForm'].get('cellPhone').value,
      ZipCode :  this.rentalForm.controls['contactInfoForm'].get('postalCode').value,
      WorkPhone :  this.rentalForm.controls['contactInfoForm'].get('workPhone').value,
      Email:  this.rentalForm.controls['contactInfoForm'].get('email').value ,
      MarketId: this.marketId     
    }
    if(this.vendorId != 0){
        this.subscriptions.push(this.vendorService.UpdateVendor(vendorinfo).subscribe((res) => {
          if (res.Success) {
            this.toastr.info(res.Message,$localize`Success`, this.toasterConfig);
          }
          else {
            this.toastr.error(res.Message,$localize`Error`, this.toasterConfig)
          }
          this.loaderIcon = Promise.resolve(false);
          this.rentalForm['controls'].contactInfoForm.markAsPristine();
          
        }, (err) => {
          this.toastr.error($localize`Something Went Wrong`,$localize`Error`, this.toasterConfig)
          this.loaderIcon = Promise.resolve(false);
        }));
    }
  }
  
  rentOnline(){
    this.showrentOnline=true;
    this.showContactInfo=false;
    this.showLoginInfo=false;
    this.showBookings=false;
    this.showReviewRegistration=false;
    this.pageName="Rent Online";
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnBookings.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRegistration.nativeElement, 'background-color', 'white');
  }

  getWebRentalSetting(){
    const sub = this.marketInfoService.getWebRentalSetting(this.marketId).subscribe((res: any) => {
      if (res) {
        this.AllowBookingsDaily =res.AllowBookingsDaily;
        this.AllowBookingsMonthly = res.AllowBookingsMonthly;
        this.AllowBookingsCm =res.AllowBookingsCm;
        this.AllowBookingsWeekly = res.AllowBookingsWeekly;
        this.AllowBookingsCw = res.AllowBookingsCw;       
        this.rentalForm.get('rentalTypes').setValue(res.DefaultRentalType.toString());
        this.rentalForm.get('bookingRentalTypes').setValue(res.DefaultRentalType.toString());

        if (this.sharedDataService.rentalType) {
          this.rentalForm.get('rentalTypes').setValue(this.sharedDataService.rentalType.toString());
          this.sharedDataService.rentalType = "";
          this.sharedDataService.paymentSharedData.RentalMethod = "";
        }
      }
    }, err => {
    });
    this.subscriptions.push(sub);
  }

  contactInfo(){
    this.showrentOnline=false;
    this.showContactInfo=true;
    this.showLoginInfo=false;
    this.showBookings=false;
    this.showReviewRegistration=false;
    this.pageName="Current Contact Information";
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnBookings.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRegistration.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
  }

  LoginInfo(){
    this.showrentOnline=false;
    this.showContactInfo=false;
    this.showLoginInfo=true;
    this.showBookings=false;
    this.showReviewRegistration=false;
    this.pageName="Online Login Info";
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnBookings.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRegistration.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
  }

  MyBookings(){
    this.showrentOnline=false;
    this.showContactInfo=false;
    this.showLoginInfo=false;
    this.showBookings=true;
    this.showReviewRegistration=false;
    this.pageName="My Bookings";
    this.renderer.setStyle(this.btnBookings.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnRegistration.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
  }

  ReviewRegistration(){
    this.showrentOnline=false;
    this.showContactInfo=false;
    this.showLoginInfo=false;
    this.showBookings=false;
    this.showReviewRegistration=true;
    this.pageName="Renew Registration";
    this.renderer.setStyle(this.btnRegistration.nativeElement, 'background-color', '#DDEBFC');
    this.renderer.setStyle(this.btnRentOnline.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.ContactInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnLoginInfo.nativeElement, 'background-color', 'white');
    this.renderer.setStyle(this.btnBookings.nativeElement, 'background-color', 'white');
  }

  setDirtyFlag(eve) {
   
  }

  isCancelClicked(eve) {
    this.rentalForm.get('rentalTypes').setValue('0');   
    setTimeout(() => {
      this.rentalForm.get('rentalTypes').setValue(eve);
    }, 0); 
  }

  cancelContactModification() {
    if (this.rentalForm['controls'].contactInfoForm.dirty) {
      Swal.fire({
        title: $localize`Confirm`,
        text: $localize`Unsaved changes will be lost. Are you sure you want to cancel?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: $localize`Yes`,
        cancelButtonText: $localize`No`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.getVendorInfobyId();
          this.rentalForm['controls'].contactInfoForm.markAsPristine();
        } else {
          //do nothing
        }
      });
    } else {
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
