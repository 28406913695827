<form [formGroup]="rentalForm">
    <div *ngIf="!displayLayout">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4"></div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-right">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 mblRightToLeft mt-1">
                        <label class="mt-2 mb-0">Select Layout Part</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 mblRightToLeft mt-2">
                        <select class="form-control" formControlName="marketParts">
                            <option *ngFor="let item of parts" [value]="item.PartId">{{item.PartDescription}}</option>
                        </select>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-2 mblRightToLeft mt-2">
                    <button class="btn btn-primary py-2" data-toggle="modal" data-target="#modalLayout"
                    (click)="openLayout()">Select Booth</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                <div class="card shadow mt-2">
                    <div class="card-header fw-700">
                        * Select Market Day(s)Here
                    </div>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item" *ngIf="monday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="Monday" formControlName="marketWeekMonday"
                                       (change)="toggleDaySelection('Monday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="Monday" i18n>
                                    Monday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="tuesday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="Tuesday" formControlName="marketWeekTuesday"
                                       (change)="toggleDaySelection('Tuesday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="Tuesday" i18n>
                                    Tuesday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="wednesday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="Wednesday" formControlName="marketWeekWednesday"
                                       (change)="toggleDaySelection('Wednesday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="Wednesday" i18n>
                                    Wednesday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="thursay">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="Thursday" formControlName="marketWeekThursday"
                                       (change)="toggleDaySelection('Thursday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="Thursday" i18n>
                                    Thursday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="friday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="Friday" formControlName="marketWeekFriday"
                                       (change)="toggleDaySelection('Friday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="Friday" i18n>
                                    Friday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="saturday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkSaturday" formControlName="marketWeekSaturday"
                                       (change)="toggleDaySelection('Saturday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="chkSaturday" i18n>
                                    Saturday
                                </label>
                            </div>
                        </li>
                        <li class="list-group-item" *ngIf="sunday">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" id="chkSunday" formControlName="marketWeekSunday"
                                       (change)="toggleDaySelection('Sunday', $event)">
                                <label class="form-check-label ml-2 mt-1" for="chkSunday" i18n>
                                    Sunday
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="card shadow mt-2">
                    <div class="card-header fw-700">
                        Rental Day(s):
                    </div>
                    <ul class="list-group list-group-flush marketDates">
                        <li class="list-group-item" *ngFor="let item of filteredMarketDays; let i=index">
                            <div class="form-check d-flex align-items-center">
                                <input class="form-check-input CheckboxClass" type="checkbox" [id]="'chk' + (i + 1)" (change)="checkboxChanged($event,item)">
                                <label class="form-check-label ml-2 mt-1" [attr.for]="'chk' + (i + 1)" i18n>
                                    {{item.MarketDays}}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 text-right">
                <div class="vendorDetails mt-2">
                    <ejs-grid #gridDailyRental [dataSource]='selectedBooths' class="Table_Height_store table-striped table-ipad"
                        [gridLines]='lines' height="150" allowSorting='true' [sortSettings]='sortOptions' [textWrapSettings]="textWrapSettings" allowTextWrap="true" >
                        <e-columns>
                            <e-column field='BoothNumber' headerText='Booth#' textAlign='left' width=130>
                            </e-column>
                            <e-column field='BoothType' headerText='Booth Type' textAlign='left' width=140>
                            </e-column>
                            <e-column field='Product' headerText='Product' textAlign='left' width=210>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <select class="form-control" [(ngModel)]="data.Product" [ngModelOptions]="{standalone: true}"
                                            (change)="productChange(data)">
                                            <option *ngFor="let item of vendorProducts" [value]="item.ProductId">
                                                {{item.ProductName}}</option>
                                        </select></span>
                                </ng-template>
                            </e-column>
                            <e-column field='RentalDay' headerText='Rental Day' textAlign='left' width=150>
                            </e-column>
                            <e-column field='Rent' headerText='Rent' textAlign='left' width=120>                               
                            </e-column>
                            <e-column field='SalesTax' headerText='Sales Tax' textAlign='left' width=210>
                            </e-column>
                            <e-column field='CityTax' headerText='Sec Level Tax' textAlign='left' width=210>
                            </e-column>
                            <e-column headerText='Delete' field="Delete" textAlign='center' width=150>
                                <ng-template #template let-data>
                                    <span class="Delete">
                                        <button class="colDelete" (click)="deleteSelectedBooth(data)"><img
                                                src="../assets/images/Delete_Icon.svg" class="img-fluid"
                                                style="max-width: 80% !important;" /></button>
                                    </span>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
                <div class="row" *ngIf="AllowRentingOtherItems">
                    <div class="col-12 text-right mt-2">
                        <button class="btn btn-primary btnAdd" data-toggle="modal" data-target="#modalOtherCharges">Select
                            Other Item</button>
                    </div>
                </div>
                <div class="vendorDetails mt-2" *ngIf="AllowRentingOtherItems">
                    <ejs-grid #gridOtherRental [dataSource]='selectedOtherItems'
                        class="Table_Height_store table-striped table-ipad" height='150' allowSorting='true'
                        [sortSettings]='sortOtherItemsOptions' [textWrapSettings]="textWrapSettings" allowTextWrap="true" >
                        <e-columns>
                            <e-column field='OtherItem' headerText='Item' textAlign='left' width=180>
                            </e-column>
                            <e-column field='OtherItemCharge' headerText='Charge' textAlign='left' width=140>                               
                            </e-column>
                            <e-column field='Quantity' headerText='Qty' textAlign='left' width=120>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <input type="text" (keydown)="onCheckNumKeyPress($event)" class="form-control" 
                                        [(ngModel)]="data.Quantity" [ngModelOptions]="{standalone: true}" (blur)="updateOtherTotalRent(data)">
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column field='BoothNo' headerText='Booth#' textAlign='left' width=120>
                            </e-column>
                            <e-column field='RentalDay' headerText='Day' textAlign='left' width=140>
                            </e-column>
                            <e-column field='OtherItemtotal' headerText='Total' textAlign='left' width=150>
                            </e-column>
                            <e-column field='SalesTax' headerText='Sales Tax' textAlign='left' width=150>
                            </e-column>
                            <e-column field='CityTax' headerText='Sec Level Tax' textAlign='left' width=160>
                            </e-column>
                            <e-column field='Reference' headerText='Reference' textAlign='left' width=170>
                                <ng-template #template let-data>
                                    <span class="dataName">
                                        <input type="text" class="form-control" (blur)="updateOtherItemReference(data)"
                                            [disabled]="!data.NewItem" [(ngModel)]="data.Reference"
                                            [ngModelOptions]="{standalone: true}">
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column headerText='Delete' field="Delete" textAlign='center' width='150'>
                                <ng-template #template let-data>
                                    <span class="Delete">
                                        <button class="colDelete" (click)="deleteOtherRentalCharges(data)"><img
                                                src="data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbl9tYXRlcmlhbC1kZWxldGUiIGRhdGEtbmFtZT0iSWNvbiBtYXRlcmlhbC1kZWxldGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjE1LjIzNyIgaGVpZ2h0PSIxOS41OTEiIHZpZXdCb3g9IjAgMCAxNS4yMzcgMTkuNTkxIj4NCiAgPHBhdGggaWQ9Ikljb25fbWF0ZXJpYWwtZGVsZXRlLTIiIGRhdGEtbmFtZT0iSWNvbiBtYXRlcmlhbC1kZWxldGUiIGQ9Ik04LjU4OCwyMS45MTRhMi4xODMsMi4xODMsMCwwLDAsMi4xNzcsMi4xNzdoOC43MDdhMi4xODMsMi4xODMsMCwwLDAsMi4xNzctMi4xNzdWOC44NTNIOC41ODhaTTIyLjczNyw1LjU4OEgxOC45MjhMMTcuODQsNC41SDEyLjRMMTEuMzA5LDUuNTg4SDcuNVY3Ljc2NUgyMi43MzdaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNy41IC00LjUpIiBmaWxsPSIjMTA2RUJFIi8+DQo8L3N2Zz4NCg=="
                                                class="img-fluid" style="max-width: 80% !important;" /></button>
                                    </span>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>

                <div class="vendorDetails1 mt-2">
                    <div class="float-right bottom_button mt-sm-2 w-100 d-flex" *ngIf="!newVendor">
                        <button type="button" i18n class="btn btnCancel mr-1" (click)="cancelRental()">Cancel</button>
                        <button type="button" i18n class="btn btn-primary" [disabled]="disableSavePayment()" (click)="saveRentalWithPayment()">Pay</button>
                    </div>
                    <div class="float-right bottom_button mt-sm-2 w-100 d-flex" *ngIf="newVendor">
                        <button type="button" i18n class="btn btnCancel mr-1" (click)="cancelRental()">Cancel</button>
                        <button type="button" i18n class="btn btn-primary" [disabled]="disableSavePayment()" (click)="rentOnlineNextTab()">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<div *ngIf="displayLayout">
    <app-rentallayout-online [marketId]="marketId" [layoutParts]="layoutParts" rentalType="D"
        (selectedBoothsFromLayout)="selectedBoothsFromLayout($event)" (CloseLayout)="closeLayoutForm()"
        (removeBoothCharges)="removeBoothCharges($event)"></app-rentallayout-online>
</div>

<div class="modal fade bd-example-modal-xl" #modalOtherCharges id="modalOtherCharges" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content contentNewBooths">
            <div class="modal-header modal-headerNewBooths">
                <h5 class="modal-title" id="exampleModalCenterTitle" i18n>Other Charges</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-bodyNewBooths">
                <div class="row mt-0">
                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-left">
                        <label i18n>Rental Day</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                        <select class="form-control" [(ngModel)]="otherChargeMarketDay"
                            (change)="getBoothsAndItemsForOtherCharges()">
                            <option *ngFor="let item of filteredMarketDays" [value]="item.MarketDays">{{item.MarketDays |
                                date :'MM/dd/yyyy'}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-left">
                        <label>Search Value</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 text-left">
                        <input type="text" class="form-control" [(ngModel)]="otherItemSearchText"
                            (keyup)="searchOtherItem()">
                    </div>
                </div>
                <div class="row" style="height: 46vh;overflow-y: scroll;">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-2">
                        <div class="availableBoothsHeading pt-1 pb-1">
                            <label i18n>Other Charges</label>
                        </div>
                        <div class="scroll_outer">
                                <table class="table table-striped table-hover followup-table mb-0">
                                    <thead>
                                        <tr>
                                            <th class="text-left tblAvailableBooths" width="40" i18n></th>
                                            <th class="text-left tblAvailableBooths" width="140" i18n>Items</th>
                                            <th class="text-left tblAvailableBooths" width="100"
                                                *ngIf="displayOtherChargeByBooth" i18n>Booth#</th>
                                            <th class="text-left tblAvailableBooths" width="120" i18n>Charges</th>
                                            <th class="text-left tblAvailableBooths" width="90" i18n>Qty</th>
                                            <th class="text-center tblAvailableBooths" width="100" i18n>Total</th>
                                            <th class="text-left tblAvailableBooths" width="130" i18n>Reference</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of otherRentalCharges">
                                            <td class="text-left">
                                                <label class="pure-material-checkbox">
                                                    <input type="checkbox" [checked]="isDateSelected(item)"
                                                        [(ngModel)]="item.select"
                                                        (change)="setDefaultRentalItem($event,item)">
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td class="text-left">
                                                {{item.OtherItem}}</td>
                                            <th class="text-left tblAvailableBooths" style="width: 35%;"
                                                *ngIf="displayOtherChargeByBooth">
                                                <select class="form-control" [(ngModel)]="item.othChgBooths"
                                                    (change)="otherItemUpdate(item)"
                                                    [disabled]="!item.otherItemChecked">
                                                    <option value="none">None</option>
                                                    <option *ngFor="let booth of boothsForOtherCharge"
                                                        [value]="booth.BoothNumber">{{booth.BoothNumber}}</option>
                                                </select>
                                            </th>
                                            <td class="text-left">
                                                <input type="text" class="form-control mt-2 mb-2 text-right"
                                                    disabled=true
                                                    [(ngModel)]="item.OtherItemCharge"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="otherItemUpdate(item)">
                                            </td>
                                            <td class="text-left"><input
                                                    type="number" [disabled]="!item.otherItemChecked"
                                                    class="form-control mt-2 mb-2" [(ngModel)]="item.Quantity" min="1"
                                                    onkeydown="return false" (ngModelChange)="otherItemUpdate(item)">
                                            </td>
                                            <td class="text-right">
                                                {{item.OtherItemtotal}}</td>
                                            <td class="text-left">
                                                <input type="text" class="form-control mt-2 mb-2"
                                                    [(ngModel)]="item.Reference" [disabled]="!item.otherItemChecked"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (keyup)="otherItemUpdate(item)">
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer d-block pt-1 pb-2">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-1">
                        <button class="btn btn-primary mr-1" (click)="addOtherCharges()" data-dismiss="modal"
                            aria-label="Close" i18n>OK</button>
                        <button class="btn btnCancel" data-dismiss="modal" aria-label="Close" i18n>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Modal for Resale Number -->
<div class="modal fade bd-example-modal-xl" #modalResaleNo id="modalResaleNo" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header modal-headerNewBooths">
                <h5 class="modal-title" id="exampleModalCenterTitle" i18n>{{ResaleOrBusinessHeader}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeResaleModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left" *ngIf="resaleNoDisplay">
                        <div class="checkbox-wrapper mt-2">
                            <label class="pure-material-checkbox">Resale Number is required!</label>
                        </div>
                        <hr class="my-3">
                        <div class="col-md-12 col-8 mt-2">
                            <label for="resaleNumberInput">Resale Number</label>
                            <input type="text" id="resaleNumberInput" class="form-control" [(ngModel)]="resaleNo" maxlength="30" />
                        </div>
                    </div>
                
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left" *ngIf="businessLicenseDisplay">
                        <div class="checkbox-wrapper mt-2">
                            <label class="pure-material-checkbox">Business License Number is required!</label>
                        </div>
                        <hr class="my-3">
                        <div class="col-md-12 col-8 mt-2">
                            <label for="businesLicenseNumberInp">Business License Number</label>
                            <input type="text" id="businesLicenseNumberInp" class="form-control" [(ngModel)]="businessLicenseNumber"
                                maxlength="30" />
                        </div>
                        <div class="col-md-12 col-8 mt-2">
                            <label for="businesLicenseExpDateInp">Business Expiry Date</label>
                            <input type="date" class="form-control" [(ngModel)]="businessLicenseExpiryDate"
                                [placeholder]="'Business License Expiry Date'" i18n-placeholder="Business License Expiry Date">
                        </div>
                    </div>
                </div>

                <div class="modal-footer d-block pt-1 pb-2">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-1">
                            <button class="btn btn-primary mr-1" data-dismiss="modal" aria-label="Close" i18n
                                (click)="AddBoothsOnGridOnValidationSucess()">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal for Resale Number -->
