import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { ICountry, IStates } from '../Shared/models/GeneralModel';
@Injectable({
    providedIn: 'root',
})

export class GeneralService {
    private baseURL: string;
    constructor(private http: HttpClient,
    ) {
        this.baseURL = environment.apiBaseUrl;
    }

    public options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    getCountries(): Observable<ICountry[]> {
        return this.http.get<ICountry[]>(
            `${this.baseURL}/General/GetCountries`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const Countries = res.body as ICountry[];
                    return Countries;
                }),
                catchError(this.handleError)
            );
    }

    getUSStates(): Observable<IStates[]> {
        return this.http.get<IStates[]>(
            `${this.baseURL}/General/GetUSStates`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const states = res.body as IStates[];
                    return states;
                }),
                catchError(this.handleError)
            );
    }

    GetVendorTypes(marketId): Observable<any> {
    return this.http.get<any>(
      `${this.baseURL}/admin/VendorType/GetVendorTypes/${marketId}`,
      { observe: 'response' })
      .pipe(
        map(res => {
          const result = res.body as any;
          return result;
        }),
        catchError(this.handleError)
      );
  }

    GetSecurityQuestion(): Observable<any> {
        return this.http.get<any>(
            `${this.baseURL}/General/GetVendorLoginSecurityQuestion`,
            { observe: 'response' })
            .pipe(
                map(res => {
                    const result = res as any;
                    return result;
                }),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        console.error('server error:', error);
        if (error.error instanceof Error) {
            const errMessage = error.error.message;
            return throwError(errMessage);
        }
        return throwError(error || 'Node.js server error');
    }
}