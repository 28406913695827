import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { NewsService } from 'src/app/services/News.service';
import { DatePipe } from "@angular/common";
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent {
  MarketId: any;
  private subscriptions: Subscription[] = [];
  news: any[];
  selectedNews: any;
  error: any;
  filteredNews: any[];
  isMoreNews: boolean;
  label: string;
  constructor(public newsService: NewsService, private route: ActivatedRoute, public datePipe: DatePipe) { }

  public NewsData: any;

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.MarketId = params.marketId;
      this.getNewsData();
      this.isMoreNews = true;
      this.label = '<<More News';
      console.log('Market ID:', this.MarketId);
    });
  }

  getNewsData() {
    this.newsService.getActiveNews(this.MarketId).subscribe({
      next: (data) => {
        this.NewsData = data;
        this.filteredNews = this.NewsData.slice(0, 4);
      },
      error: (err) => {
        this.error = err;
        console.error('Error fetching news data:', err);
      }
    });

  }

  openModal(newsData: any) {
    this.selectedNews = newsData;
  }

  loadMoreNews() {
    if (this.isMoreNews) {
      this.filteredNews = this.NewsData;
      this.label = 'Less News>>';
      this.isMoreNews = false;
    } else {
      this.filteredNews = this.NewsData.slice(0,4);
      this.label = '<<More News';
      this.isMoreNews = true;
    }
  }
}