<div class="container-fluid">
    <div class="row">
        <div class="col-lg-2 col-xl-2 alignMenu shadow text-left pt-2 px-1">
            <app-rental-menu></app-rental-menu>
        </div>
        <div class="col-lg-10 col-xl-10 bgMainSection bgBlue text-left">
            <div class="container mainContainer">
                <div class="row">
                    <div class="col-12 text-left mt-4">
                        <label class="mainHeading">News</label>
                    </div>
                </div>
                <div class="bgWghite shadow mt-2">
                    <div *ngFor="let data of filteredNews let i = index;">
                        <div class="container">
                            <div class="row pt-4 pb-3">
                                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 text-start">
                                    <label class="newsHeading mt-2">{{data.NewsTitle}}</label>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-2 col-xl-2 text-start">
                                    <button class="btn btn-primary" data-toggle="modal" data-target="#modalReadMore"
                                        (click)="openModal(data)">Read More</button>
                                </div>
                            </div>
                        </div>
                        <hr class="my-0" />
                    </div>
                    <div class="container" [hidden]="this.NewsData.length<=4">
                        <div class="row">
                            <div class="col-12 text-start my-3">
                                <a style="font-size: 15px; color: #007bff;" (click)="loadMoreNews()">
                                    <label><u>{{label}}</u></label>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Read More-->
    <!-- <div class=".d_box modal fade" id="modalReadMore" tabindex="-1" role="dialog" aria-labelledby="modalReadMoreTitle"
        aria-hidden="true">
        <div class="d_box modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body py-5">
                    <h5 class="justify fw-700">{{selectedNews?.NewsTitle}}</h5>
                    <label class="justify mt-2" style="font-size: 16px;">{{selectedNews?.Description}}</label>
                </div>
            </div>
        </div>
    </div> -->
    
    <div class="modal fade" id="modalReadMore" tabindex="-1" role="dialog" aria-labelledby="modalReadMoreTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel" style="font-size: 18px;">
                        <b>{{selectedNews?.NewsTitle}}</b>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p class="mt-2" style="font-size: 16px; white-space: pre-wrap;">{{selectedNews?.Description}}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Read More Ends Here-->
</div>